(function () {
'use strict';

angular.module('app')

.directive('userNameMenu', ['users', 'utils', '$state', 'api', function(users, utils, $state, api) {
    return {
        restrict: 'E',
        replace: true,
        // Isolating scope
        scope: true,
        templateUrl: 'assets/views/directives/user-name-menu.html',
        link: function($scope, el) {
            $scope.data = {};

            function updateUser() {
                $scope.data.avatar = users.getAvatar();
                $scope.data.topName = users.getUsernameForCurrentUser();
                if(users.current.first_name && users.current.last_name) {
                    $scope.data.topName = users.current.first_name + ' ' + users.current.last_name;
                    $scope.data.bottomName = users.getUsernameForCurrentUser();
                }
                $scope.data.isAdmin = users.current.isAdmin;
                $scope.data.isOrgOwner = users.current.isOrgOwner;
                $scope.data.isExecutor = users.accounts.length > 0 ? true : false;
                $scope.data.hasReceipts = (users.current.stripe_id && utils.getSetting('enablePayments'));
            }

            $scope.showAdminArea = function() {
                if ($scope.data.isOrgOwner) {
                    $state.go('admin.organization');
                } else {
                    $state.go('admin.analytics');
                }

            };

            $scope.showSettings= function($event, type) {
                users.showAccountSettingsModal($event, type);
            };

            $scope.showSwitchAccounts = function() {
                users.showSwitchAccountsModal();
            };

            $scope.showDeleteAccount = function() {
                if ($scope.data.isOrgOwner) {
                    utils.confirm({
                        title: 'organization.ownerAccountDeleteWarning',
                        subcontentHTML: "You are currently the owner of <b>"+ users.current.organization.name +"</b>. You must contact <b><a href='mailto:support@storyhives.com'>Story Hive</a></b> to remove you as the organization owner. Once that has been completed you will be free to delete your account.",
                        ok: 'OK',
                        type: 'alert'
                    });
                } else {
                    utils.confirm({
                        title: 'deleteAccount',
                        content: 'deleteAccountInfo',
                        ok: 'ok',
                        onConfirm: function() {
                            api.user.deleteAccount(users.current.id).then(function(response) {
                                utils.showToast('accountDeletedSuccessful', true);
                                users.logout();
                            }, function(response) {
                                utils.showToast('genericError', true);
                            });
                        }
                    });
                }
            };

            $scope.showInvoices= function() {
                $state.go('dashboard.invoices');
            };

            $scope.$on('user.changed', function() {
                updateUser();
            });

            $scope.logout = function() {
                users.logout();
            };

            $scope.isMyHopeUser = function() {
                return users.isMyHopeUser();          
            };

            updateUser();
        }
    };
}]);
}());
