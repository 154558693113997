(function () {
    'use strict';

    angular.module('app')
    .directive('staticPage', ['$state', '$rootScope', 'utils', 'api', '$compile', '$stateParams', function($state, $rootScope, utils, api, $compile,$stateParams) {
        return {
            restrict: 'E',
            scope: {
            },
            template: '<div></div>',
            link: function($scope, el, attrs) {
                $scope.content = "";
                var page = $state.current.name == 'dashboard.footer' ?  $stateParams.slug : $state.current.name;
                
                api.footermenu.getPageContent(page).then(function(response) {
                    var content = '<div style="margin-left: 15px; margin-top: 15px; height: 100%; overflow: auto;">' + response.content + '</div>';
                    var newEl = angular.element(content);
                    el.append(newEl);
                    $compile(newEl)($scope);
                }, function(response) {
                    utils.showToast(response.data);
                });

                $scope.data = {
                    baseUrl: $rootScope.baseUrl,
                    isEmbedded: $state.includes('dashboard')
                };             
            }
        };
    }]);
}());
