(function () {
    'use strict';

    angular.module('pixie.dashboard')

        /**
         * This is the main view center dashboard.
         */
        .directive('itemsView', ['selectedItems', 'utils', 'fileTypes', '$rootScope', 'localStorage', 'contextMenu', 'folders', '$stateParams', function (selectedItems, utils, fileTypes, $rootScope, localStorage, contextMenu, folders, $stateParams) {
            return {
                restrict: 'E',
                templateUrl: 'itemsView.html',
                scope: {
                    items: '='
                },
                link: function ($scope) {
                    $scope.fileTypes = fileTypes; // I hate doing this.
                    $scope.utils = utils; // Ugh.
                    $scope.data = {
                        selectedView: localStorage.get('selectedView', 'grid'),
                        autoLoadMore: false
                    };
                    $scope.images = getImages();
                    $scope.showedItems = [];
                    $scope.itemsPerPage = utils.getSetting('dash_files_num'); //100;
                    $scope.itemsPerPage = parseInt(parseInt($scope.itemsPerPage) + parseInt($rootScope.ajaxProgress.totalSubFolders) || 0);
                    $scope.nextPage = function (more) {
                        var itemsAdded = 0;
                        if (more) {
                            folders.selectByShareId($rootScope.ajaxProgress.currentSharedID, false, false, false);
                        }
                        if ($rootScope.ajaxProgress.totalItems > $scope.showedItems.length) {
                            $scope.items.forEach(function (element, index) {
                                if ( (itemsAdded < $scope.itemsPerPage) && (!checkElementNotExists(element)) ) {
                                    $scope.showedItems.push(element);
                                    itemsAdded++;
                                } else {
                                    return;
                                }
                            });
                        } else {
                            $scope.showedItems = $scope.items;
                        }
                    };

                    $scope.$on('showedItems.refresh', function (e, data) {
                        $scope.nextPage();
                    });

                    function getImages() {
                        var images = [];
                        $scope.items.forEach(function(item) {
                            if(item.type != 'folder' && item.mime.includes('image')) {
                                images.push(item);
                            }
                        });
                        return images;
                    }

                    function checkElementNotExists(element) {
                        var isExists = false;
                        $scope.showedItems.forEach(function (showedItem) {
                            if (showedItem.share_id == element.share_id) {
                                isExists = true;
                            }
                        });
                        return isExists;
                    }

                    $scope.$watchCollection('items', function (items) {
                        // if ($scope.data.autoLoadMore) {
                            $scope.nextPage();
                            // $scope.data.autoLoadMore = false;
                        // }
                    });

                    $scope.$on('items.auto.loadmore', function () {
                        $scope.data.autoLoadMore = true;
                    });

                    $scope.nextPage();

                    $scope.$on('items.view.changed', function (evt, data) {
                        $scope.data.selectedView = data.view;
                    });

                    $scope.previewSelected = function () {
                        selectedItems.preview();
                    };

                    $scope.showMoreOptions = function (item, $event) {
                        $('.duplicateContextMenu').remove();
                        $event.stopPropagation();
                        $event.preventDefault();
                        selectedItems.set(item, item.type, !$event.ctrlKey);
                        contextMenu.show($event, true, 'fixed');
                    };

                    $scope.formatTime = function (time) {
                        return moment.utc(time).fromNow();
                    };

                    $scope.getImageUrl = function (item) {
                        // return $rootScope.baseUrl+'user-file/'+item.share_id+'?w=167&h=131';
                        return $rootScope.baseUrl + 'user-file/' + item.share_id + '?thumb=1';
                    };

                    $scope.preview = function ($event, item) {
                        // fixme: use $event, don't use selectedItems (as it should be *this* item.)
                        selectedItems.preview();
                    };

                    $scope.filteredName = function (name, type) {
                        var result = name;
                        if (localStorage.get('stripExtensions', true) && type == 'file') {
                            result = result.replace(/\.[^/.]+$/, "");
                        }
                        return result;
                    };

                    /**
                     * Check if given item is currently selected.
                     *
                     * @param {object} item
                     * @returns {boolean}
                     */
                    $scope.itemIsSelected = function (item) {
                        return selectedItems.has(item);
                    };

                    /**
                     * Select given folder or file.
                     *
                     * @param {object} item
                     * @param {object} $event
                     */
                    $scope.selectItem = function (item, $event) {
                        //if ctrl is pressed and user clicked on item
                        //that is already selected, deselect it
                        if ($event.ctrlKey && selectedItems.has(item)) {
                            selectedItems.deselect(item);
                        } else {
                            selectedItems.set(item, item.type, !$event.ctrlKey);
                        }
                    };
                }
            };
        }]);
})();