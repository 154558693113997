(function(){
    'use strict';

//    angular.module('app', ['ngMaterial', 'app.preview'])
    angular.module('app.dialogs', ['ngMaterial', 'app.dialogs.preview'])
    .directive('itemExistsInFolder', ['$http', '$q', function($http, $q) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function($scope, $element, $attrs, ngModel) {
                var folderId;

                $scope.$watch($attrs.itemExistsInFolder, function(newValue, oldValue) {
                    folderId = newValue;
                    ngModel.$validate();
                });

                ngModel.$asyncValidators.itemExistsInFolder = function(modelValue, viewValue) {
                    return $q(function(resolve, reject) {
                        if(folderId) {
                            return $http.post('/folders/exists', { folderId: folderId, name: viewValue }).then(function(response) {
                                if(response.data.status) {
                                    reject();
                                } else {
                                    resolve();
                                }
                            }, reject);
                        } else {
                            resolve();
                        }
                    });
                };
            }
        };
    }])
    .factory('dialogManager', ['$mdDialog', '$q', function($mdDialog, $q) {
        var dialogs = {
            showUpgradeDialog: function(event) {
                var onCompleteDeferred = $q.defer();
                return $mdDialog.show({
                  controller: ['$scope', 'utils', '$http', 'users', '$mdDialog', '$rootScope', 'loadingPromise', function($scope, utils, $http, users, $mdDialog, $rootScope, loadingPromise) {
                      var publicKey = utils.getSetting('stripePubKey');
                      var stripe = null;
                      var elements = null;
                      var card = null;
                      var intent = null;
                      var paymentMethod = null;

                      $scope.data = {
                          loading: true,
                          freePlanSpace: utils.formatFileSize(utils.getSetting('maxUserSpace')),
                          cardDetails: {},
                          months: [1,2,3,4,5,6,7,8,9,10,11,12],
                          years: [],
                          addingNewCard: false,
                          currentUserPlan: false,
                          planExpiresOn: false,
                          onGracePeriod: false,
                          invalidCard: true,
                          isTestMode: (publicKey.indexOf('pk_test_')===0),
                          selectedPlan: false,
                          gracePeriodMsg: '',
                          subscribedMsg: '',
                          errors: '',
                          clientSecret: ''
                      };

                      var current = new Date().getFullYear();
                      for (var i = current; i < current+15; i++) {
                          $scope.data.years.push(i);
                      }

                      function getPlanPrice(plan) {
                          var result = '';
                          if ($scope.plans) {
                              for (var ii = 0; ii < $scope.plans.length; ii++) {
                                  if ($scope.plans[ii].id == plan) {
                                      switch($scope.plans[ii].currency.toUpperCase()) {
                                      case 'USD':
                                          result = '$' + $scope.plans[ii].amount;
                                          break;
                                      default:
                                          result = $scope.plans[ii].amount+' '+$scope.plans[ii].currency.toUpperCase();
                                          break;
                                      }
                                  }
                              }
                          }
                          return result;
                      }

                      function getPlanName(plan) {
                          var result = '';
                          if ($scope.plans) {
                              for (var i = 0; i < $scope.plans.length; i++) {
                                  if ($scope.plans[i].id == plan) {
                                      result = $scope.plans[i].name;
                                  }
                              }
                          }
                          return result;
                      }

                      function getIntent() {
                        $http.get('payments/get-intent').then(function(response) {
                          intent = response.data;
                          $scope.data.clientSecret = intent.client_secret;
                        }, function(error) {
                          utils.showToast(error);
                          $scope.data.loading = false;
                        });
                      }

                      function loadPlans() {
                          $scope.data.loading = true;

                          $http.get('payments/plans').then(function(response) {
                              $scope.plans = response.data;
                              updateInfoMessages($scope);
                              $scope.data.loading = false;
                          });
                      }

                      function updateInfoMessages() {
                          $scope.data.gracePeriodMsg  = utils.trustHtml(utils.trans('onGracePeriodExpl', {plan: getPlanName($scope.data.currentUserPlan), date: moment.utc($scope.data.planExpiresOn).format("dddd, MMMM Do YYYY, h:mm:ss a")}));
                          $scope.data.subscribedMsg = utils.trustHtml(utils.trans('subscribedExpl', {plan: getPlanName($scope.data.currentUserPlan), cardType: users.current.card_brand, lastFour: users.current.pm_last_four, amount: getPlanPrice($scope.data.currentUserPlan) }));
                      }

                      function updateActiveDescription() {
                          // find the current user plan and if it's active
                          var activeSubscription = { stripe_plan: false, ends_at: false };
                          if(users.current.currentSubscription) {
                              activeSubscription.stripe_plan = users.current.currentSubscription.stripe_plan;
                              activeSubscription.ends_at = users.current.currentSubscription.plan_expires_on;
                          }

                          if(activeSubscription.ends_at) {
                              // set the on graceperiod.
                              activeSubscription.onGracePeriod = true; // FIXME: use trial_ends_at
                          } else {
                              activeSubscription.onGracePeriod = false;
                          }

                          $scope.data.currentUserPlan = activeSubscription.stripe_plan;
                          $scope.data.planExpiresOn = activeSubscription.ends_at;
                          $scope.data.onGracePeriod = activeSubscription.onGracePeriod;
                      }

                      /**
                       * Subscribe user to selected plan.
                       *
                       * @param {object} $scope
                       * @param {string} paymentMethod
                       */
                      function subscribe(paymentMethod) {
                        $scope.data.loading = true;
                        $http.post('payments/upgrade', {payment_method: paymentMethod, plan: $scope.data.selectedPlan}).then(function(response) {
                            users.assignCurrentUser(response.data.user);
                            utils.showToast(response.data.message);
                            $scope.closeModal();

                            $rootScope.$broadcast('user.subscribed');
                        }).finally(function() {
                            $scope.data.loading = false;
                        });
                      }

                      /**
                       * Add new credit card to current user.
                       *
                       * @param {object} $scope
                       * @param {string} paymentMethod
                       */
                      function addNewCard(paymentMethod) {
                          $http.post('payments/add-new-card', {payment_method: paymentMethod}).then(function(response) {
                              utils.showToast(response.data);
                              if (!$scope.data.addingNewCard) {
                                subscribe(paymentMethod);
                              }
                              $scope.closeModal();
                          }).finally(function() {
                              $scope.data.loading = false;
                          });
                      }

                      $scope.unsubscribe = function() {
                          utils.confirm({
                              title: 'cancelSub',
                              content: 'cancelSubConfirm',
                              ok: 'unsubscribe',
                              onConfirm: function() {
                                  $scope.data.loading = true;
                                  $http.post('payments/unsubscribe').then(function(response) {
                                      $scope.data.loading = false;
                                      $scope.data.selectedPlan = false;
                                      $scope.data.onGracePeriod = true;
                                      users.assignCurrentUser(response.data);
                                      utils.showToast('unsubSuccess', true);
                                      updateActiveDescription();
                                      updateInfoMessages();
                                  });
                              }
                          });
                      };

                      $scope.showNewCardForm = function() {
                          $scope.data.addingNewCard= true;
                          loadStripeElements();
                      };

                      $scope.hideNewCardForm = function() {
                          $scope.data.addingNewCard = false;
                          $scope.data.errors = '';
                      };

                      $scope.addNewCard = function() {
                        stripe.confirmCardSetup(
                          intent.client_secret, {
                              payment_method: {
                                  card: card
                              }
                          }
                        ).then(function(response) {
                          if(response.setupIntent.payment_method != '' || response.setupIntent.setup_intent.payment_method != '') {
                            paymentMethod = response.setupIntent.payment_method != '' ? response.setupIntent.payment_method : response.setupIntent.setup_intent.payment_method;
                            addNewCard(paymentMethod);
                          }
                        });
                      };

                      $scope.resumePlan = function(plan) {
                          $scope.data.loading = true;

                          $http.post('payments/resume', {plan: plan.id}).then(function(response) {
                              $scope.data.currentUserPlan = plan.id;
                              $scope.data.onGracePeriod   = false;
                              users.assignCurrentUser(response.data);
                              updateActiveDescription();
                              updateInfoMessages();
                              utils.showToast('subResumeSuccess', true);
                          }).finally(function() {
                              $scope.data.loading = false;
                          });
                      };

                      $scope.closeModal = function() {
                          $mdDialog.cancel();
                      };

                      $scope.selectPlan = function(plan) {
                          if ($scope.data.currentUserPlan && $scope.data.currentUserPlan !== plan.id) {
                              $scope.data.loading = true;

                              $http.post('payments/swap-plan', {plan: plan.id}).then(function(response) {
                                  $scope.data.loading = false;
                                  $scope.data.currentUserPlan = plan.id;
                                  updateInfoMessages();
                                  utils.showToast(response.data);
                                  $rootScope.$broadcast('user.subscribed');
                              });
                          } else {
                              $scope.data.selectedPlan = plan ? plan.id : false;
                          }
                      };

                      function loadStripeElements() {
                        if ( angular.element('#card-element').length ) {
                          stripe = Stripe(publicKey);
                          elements = stripe.elements();

                          var style = {
                            base: {
                              color: '#32325d',
                              lineHeight: '18px',
                              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                              fontSmoothing: 'antialiased',
                              fontSize: '16px',
                              '::placeholder': {
                                color: '#aab7c4'
                              }
                            },
                            invalid: {
                              color: '#fa755a',
                              iconColor: '#fa755a'
                            }
                          };

                          // Create an instance of the card Element.
                          card = elements.create('card', {hidePostalCode: true, style: style});

                          // Add an instance of the card Element into the `card-element` <div>.
                          card.mount('#card-element');

                          card.addEventListener('change', function(event) {
                            var displayError = document.getElementById('card-errors');
                            if (event.error) {
                              displayError.textContent = event.error.message;
                              $scope.$apply(function() { $scope.data.invalidCard = true; });
                            } else {
                              displayError.textContent = '';
                              $scope.$apply(function() { $scope.data.invalidCard = false; });
                            }
                          });
                        }
                      }

                      loadingPromise().then(function() {
                        loadStripeElements();
                      });

                      // initial setup stuff...
                      getIntent();
	                    updateActiveDescription();
                      loadPlans();
                  }],
                  onComplete: onCompleteDeferred.resolve,
                  locals: {
                    loadingPromise: function () {
                        return onCompleteDeferred.promise;
                    },
                    success: function() {}
                  },
                  templateUrl: 'assets/views/modals/upgrade-account.html',
                  parent: angular.element(document.body),
                  targetEvent: event,
                  clickOutsideToClose:true,
                  fullscreen: true // Only for -xs, -sm breakpoints.
                });
            },

            showMoveSharedDialog: function(item) {
              return $mdDialog.show({
                  templateUrl: 'assets/views/modals/move-shared.html',
                  clickOutsideToClose: true,
                  controller: ['$scope', 'folders', '$mdDialog', 'item', function($scope, folders, $mdDialog, item) {
                      $scope.folders = folders;
                      $scope.data = {
                        type: item.type,
                        sharingUser: item.ownerName,
                        previewUrl: item.preview_url,
                        result: {
                          // folderId: null,
                          // Set as destination root as part of FF-115
                          // folderId: folders.getRoot().share_id,
                          name: item.originalName,
                        }
                      };

                      $scope.close = function() {
                          $mdDialog.cancel();
                      };

                      $scope.canMoveTo = function(folder) {
                        return (folder.is_writable);
                      };

                      $scope.move = function() {
                          $mdDialog.hide($scope.data.result);
                      };
                  }],
                  locals: {
                      item: item
                  }
              });
            },

            showFamilyMemberInviteDialog: function(invite) {
              return $mdDialog.show({
                  templateUrl: 'assets/views/modals/family-invitation.html',
                  clickOutsideToClose: true,
                  controller: ['$scope', '$mdDialog', 'invite', 'utils', function($scope, $mdDialog, invite, utils) {
                      $scope.data = {
                        invitationText: utils.trans('family.invitationText', { owner_name: invite.owner_name }),
                      };

                      $scope.close = function() {
                        $mdDialog.cancel();
                      };

                      $scope.accept = function() {
                        $mdDialog.hide();
                      };
                  }],
                  locals: {
                      invite: invite
                  }
              });
            },

            showMovePurchaseDialog: function(item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/move-purchased.html',
                    clickOutsideToClose: true,
                    controller: ['$scope', '$mdDialog', 'item', 'utils', 'folders', '$rootScope', 'api', function($scope, $mdDialog, item, utils, folders, $rootScope, api) {
                        $scope.folders = folders;
                        $scope.data = {
                          name: item.name,
                          type: item.type,
                          result: {
                              folderId: null,
                              name: null,
                          }
                        };

                        $scope.close = function() {
                          $mdDialog.hide();
                        };

                        $scope.move = function() {
                          api.file.moveTemplate(item.id, $scope.data.result).then(function success(template) {
                            $rootScope.$broadcast('activity.happened', 'moved', 'template', [template]);
                            var destinationFolder = folders.getById(template.folder_id);
                            utils.showToast(utils.trans('movedTemplate', { name:  template.name, folder: destinationFolder.name }));
                            $mdDialog.hide();
                          }, function error(failure) {
                            utils.showToast(failure.data);
                          });
                        };

                        $scope.canMoveTo = function(folder) {
                          return (folder.is_writable);
                        };

                    }],
                    locals: {
                        item: item
                    }
                });
            },

            showEditIntroDialog: function(event, item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/edit-intro-dialogs.html',
                    clickOutsideToClose: false,
                    locals: {
                        item: item
                    },
                    targetEvent: event,
                    controller: ['$scope', 'item', '$mdDialog', 'textAngularManager', '$timeout', function($scope, item, $mdDialog, textAngularManager, $timeout) {
                        $scope.data = {
                            modeName: item ? item.modeName : '',
                            content: item ? item.value : '',
                        };

                        $scope.save = function() {
                            $mdDialog.hide($scope.data.content);
                        };

                        $scope.close = function() {
                            $mdDialog.cancel();
                        };

                        function editorFocus() {
                            $timeout(function() {
                                var editorScope = textAngularManager.retrieveEditor('editor').scope;
                                if (editorScope) {
                                    editorScope.displayElements.text.trigger('focus');
                                }
                            }, 5);
                        }

                        editorFocus();

                        $scope.$on('imgeditor.completed', function() {
                          $timeout(function() {
                            var editorScope = textAngularManager.retrieveEditor('editor').scope;
                            if (editorScope) {
                              $scope.data.content = editorScope.html;
                            }
                          }, 10);
                        });
                    }]
                });
            },

            showEditFaqDialog: function(event, item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/edit-faq.html',
                    clickOutsideToClose: false,
                    locals: {
                        item: item
                    },
                    targetEvent: event,
                    controller: ['$scope', 'item', '$mdDialog', 'newUploadManager', '$rootScope', 'utils', 'api', 'textAngularManager', '$timeout', function($scope, item, $mdDialog, newUploadManager, $rootScope, utils, api, textAngularManager, $timeout) {
                        $scope.data = {
                            question: item ? item.question : '',
                            answer: item ? item.answer : '',
                            image_url: item ? item.image_url : false,
                            video_id: item ? item.video_id : ''
                        };

                        function progress(event) {
                            // debugger;
                        }

                        api.file.getVideos().then(function (videos) {
                          $scope.data.videos = videos;
                        });

                        $scope.upload = function(files) {
                            if(files.length) {
                                newUploadManager.upload(files, { url: $rootScope.baseUrl + 'faqs/image', progress: progress, fields: {} }).then(function success(data) {
                                    if(data.length) {
                                        $scope.data.image_url = data[0].uploadedUrl;
                                    } else {
                                        utils.showToast("Something went wrong.");
                                    }
                                }, function failure(data) {
                                    utils.showToast(data);
                                });
                            }
                        };

                        $scope.save = function() {
                            var result = {
                                item: item
                            };

                            angular.forEach($scope.data, function(value, key) {
                                result[key] = value;
                            });
                            $mdDialog.hide(result);
                        };

                        $scope.close = function() {
                            $mdDialog.cancel();
                        };

                        function editorFocus() {
                            $timeout(function() {
                                var editorScope = textAngularManager.retrieveEditor('editor').scope;
                                if (editorScope) {
                                    editorScope.displayElements.text.trigger('focus');
                                }
                            }, 5);
                        }

                        editorFocus();

                        $scope.$on('imgeditor.completed', function() {
                          $timeout(function() {
                            var editorScope = textAngularManager.retrieveEditor('editor').scope;
                            if (editorScope) {
                              $scope.data.answer = editorScope.html;
                            }
                          }, 10);
                        });
                    }]
                });
            },

            showViewJoinRequestsDialog: function(item, type) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/view-join-requests.html',
                    clickOutsideToClose: true,
                    locals: {
                        item: item,
                        type: type
                    },
                    targetEvent: event,
                    controller: ['$scope', 'item', '$mdDialog', function($scope, item, $mdDialog) {
                      $scope.item = item;
                      $scope.type = type;

                      $scope.close = function() {
                          $mdDialog.cancel();
                      };
                    }]
                });
            },

            showUploadImageDialog: function() {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/upload-image-modal.html',
                clickOutsideToClose: true,
                targetEvent: event,
                controller: ['$scope', '$mdDialog', 'api', '$rootScope', 'fileTypes', '$q', '$translate', 'newUploadManager', 'utils', '$state', function($scope, $mdDialog, api, $rootScope, fileTypes, $q, $translate, newUploadManager, utils, $state) {
                  $scope.data = {
                      url: '',
                      selectedItem: false,
                      time: new Date().getTime().toString(),
                      state: 'menu',
                      folder: null,
                      breadcrumbs: [],
                      shareId: '',
                      loading: false,
                  };

                  var state = $state.current.name.split('.');

                  if (state.length == 2) {
                    if (state[1] == 'folders') {
                      state = 'doc';
                    } else if (state[1] == 'buzz') {
                      state = 'timeline';
                    } else {
                      state = state[1];
                    }
                  } else {
                    state = 'doc';
                  }

                  $scope.close = function() {
                      $mdDialog.cancel();
                  };

                  $scope.save = function() {
                      $scope.data.loading = true;
                      api.file.duplicateMediaFile(state, $scope.data.selectedItem.share_id).then(function(data) {
                          $mdDialog.hide({
                              shareId: data.share_id,
                              url: data.absoluteUrl
                          });
                      }, function() {
                          // handle failded case
                      }).finally(function() {
                          $scope.data.loading = false;
                      });
                  };

                  $scope.selectImage = function()  {
                      // find an existing one and remove it.
                      var id = 'dynamic-upload-image';
                      $('#'+id).remove();

                      // Create a virtual input element.
                      var input = document.createElement('input');
                      input.type = 'file';
                      input.id = id;
                      input.accept = "image/*";

                      input.onchange = function() {
                          if (this.files && this.files[0]) {
                              if (!this.files[0].size) {
                                  return;
                              }

                              var model = this;

                              return $mdDialog.show({
                                  parent: angular.element(document.body),
                                  template:
                                      '<md-dialog aria-label="Image Upload Progress">'+
                                          '<md-dialog-content>'+
                                              '<div style="margin: 10px;">'+
                                                  '<div layout="row">'+
                                                      '<div flex="none">{{ data.statusText }}</div>'+
                                                      '<div flex="flex"></div>'+
                                                      '<div ng-if="data.hasProgress" flex="none" class="percent" ng-class="{\'completed\': data.uploadCompleted }">{{data.percentageUploaded}}%</div>'+
                                                  '</div>'+
                                                  '<md-progress-linear ng-if="!data.hasProgress" class="loading-progress" md-mode="indeterminate"></md-progress-linear>'+
                                                  '<md-progress-linear ng-if="data.hasProgress" class="loading-progress" md-mode="determinate" value="{{data.percentageUploaded}}" ></md-progress-linear>'+
                                              '</div>'+
                                          '</md-dialog-content>'+
                                      '</md-dialog>',
                                  onComplete: function($scope, $element) {
                                      $scope.data = {
                                          uploadCompleted: false,
                                          percentageUploaded: 0,
                                          hasProgress: true,
                                          statusText: $translate.instant('editor.inProgressText'),
                                      };

                                      function progress(evt) {
                                          $scope.data.percentageUploaded = evt.$progress.percentageUploaded;
                                          if ($scope.data.percentageUploaded >= 100) {
                                              $scope.data.uploadCompleted = true;
                                              $scope.data.statusText = "Processing Image..";
                                              $scope.data.hasProgress = false;
                                          }
                                      }

                                      newUploadManager.upload(model.files, { url: '/files/upload-file-media', progress: progress, fields: {folder: state} }).then(function success(response) {
                                          $scope.data.uploadCompleted = true;
                                          $scope.data.statusText = $translate.instant('editor.completedText');
                                          $scope.data.url = response[0].uploadedUrl;
                                          $scope.data.shareId = response[0].shareId;
                                      }, function failure(data) {
                                          if (typeof data === 'object' && data !== null) {
                                              utils.showToast(data['file'][0]);
                                          } else {
                                              utils.showToast(data);
                                          }
                                      }).finally(function() {
                                          $('#'+id).remove();
                                          $mdDialog.hide({url: $scope.data.url, shareId: $scope.data.shareId});
                                      });
                                  }
                              }).then(function(data) {
                                $rootScope.$broadcast('post.media.info', data);
                              }, function() {
                                  //handle error
                                  $mdDialog.cancel();
                              });
                          }
                      };

                      document.body.appendChild(input);

                      // Click on a virtual input element.
                      input.click();

                      return false;
                  };

                  $scope.browseImage = function() {
                      $scope.data.folder = null;
                      $scope.data.breadcrumbs = [];
                      $scope.getFolder('root');
                      $scope.data.state = 'browse';
                  };

                  $scope.getFolder = function(shareId) {
                      api.folder.getFolder(shareId).then(function (response) {
                          var validFiles = [];

                          response.folder.files.forEach(function (item) {
                              if (item.mime.includes('image')) {
                                  validFiles.push(item);
                              }
                          });

                          response.folder.files = validFiles;
                          $scope.data.folder = response;

                          var index = $scope.data.breadcrumbs.findIndex(
                              function (element) {
                                  return (element.shareId == shareId);
                              });

                          if (index >= 0) {
                              $scope.data.breadcrumbs.length = index;
                          }

                          if (shareId == 'root') {
                              $scope.data.breadcrumbs = [{
                                  'name': response.folder.name,
                                  'shareId': response.folder.share_id
                              }];
                          }

                          if ($scope.data.breadcrumbs[$scope.data.breadcrumbs.length - 1]['shareId'] != response.folder.share_id) {
                              $scope.data.breadcrumbs.push({
                                  'name': response.folder.name,
                                  'shareId': response.folder.share_id
                              });
                          }
                      });
                  };

                  $scope.backToMainMenu = function() {
                      $scope.data.state = 'menu';
                  };

                  $scope.getMimeIcon = function(mime) {
                      return fileTypes.getMimeIcon(mime);
                  };

                  $scope.getIsThumbnail = function(item) {
                      return item.flags & 0x02 ? true : false;
                  };

                  $scope.getThumbnail = function(item) {
                      return $rootScope.baseUrl+'user-file/'+item.share_id+'?thumb=1&t='+ $scope.data.time;
                  };

                  $scope.setUrl = function (item) {
                      $scope.data.selectedItem = item;
                      $scope.data.url = item.type == 'file' ? item.absoluteUrl : '';
                  };
                }]
              });
            },

            showUploadVideoDialog: function(event, item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/upload-video.html',
                    clickOutsideToClose: true,
                    multiple: true,
                    controller: ['$scope', 'api', 'fileTypes', '$rootScope', '$state', 'newUploadManager', 'utils', '$translate', '$compile', '$window', function($scope, api, fileTypes, $rootScope, $state, newUploadManager, utils, $translate, $compile, $window) {
                        $scope.data = {
                            posterUrl: '',
                            selectedItem: false,
                            searchResults: [],
                            searchText: undefined,
                            time: new Date().getTime().toString(),
                            state: 'menu',
                            folder: null,
                            breadcrumbs: [],
                            shareId: '',
                            loading: false,
                            recordFileText: '',
                            device: 'mobile',
                            showRecord: true
                        };

                        var state = $state.current.name.split('.');
                        var recordId = 'dynamic-video';
                        if (state.length == 2) {
                          if (state[1] == 'folders') {
                            state = 'doc';
                          } else if (state[1] == 'buzz') {
                            state = 'timeline';
                          } else {
                            state = state[1];
                          }
                        } else {
                          state = 'doc';
                        }

                        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test($window.navigator.userAgent)) {
                          if ($window.innerWidth <= 768) {
                            $scope.data.device = "mobile";
                          } else {
                            $scope.data.device = "tablet";
                          }
                        } else {
                            $scope.data.device = "desktop";
                        }

                        function updateShowRecord() {
                          if ($scope.data.device == "desktop") {
                            $scope.data.showRecord = false;
                          } else {
                            $scope.data.showRecord = true;
                          }
                        }

                        updateShowRecord();

                        $scope.close = function() {
                            $mdDialog.cancel();
                        };

                        $scope.save = function() {
                            $scope.data.loading = true;
                            if ($scope.data.state == 'record') {
                                return dialogForUploadVideo([$scope.recordedFile]);
                            } else {
                                api.file.duplicateMediaFile(state, $scope.data.shareId).then(function(data) {
                                    $mdDialog.hide({
                                        shareId: data.share_id,
                                        posterUrl: data.poster_url
                                    });
                                }, function() {
                                    // handle failded case
                                }).finally(function() {
                                    $scope.data.loading = false;
                                });
                            }
                        };

                        $scope.recordVideo = function()  {
                            $('#'+recordId).remove();

                            // Create a virtual input element.
                            var input = document.createElement('input');
                            input.type = 'file';
                            input.id = recordId;
                            input.accept = "video/*";
                            input.setAttribute("record-file-upload", "recordedFile");
                            $compile(input)($scope);
                            document.body.appendChild(input);

                            // Click on a virtual input element.
                            input.click();
                        };

                        $scope.$watch('recordedFile', function(newFile, oldFile) {
                            if (!oldFile && newFile) {
                                $scope.data.recordFileText = utils.trans('uploadVideo.recordFileText', {name:  $scope.recordedFile.name});
                                $scope.data.state = 'record';
                            }
                        });

                        $scope.selectVideo = function()  {
                            // $scope.data.state = 'select';

                            // find an existing one and remove it.
                            $('#'+recordId).remove();

                            // Create a virtual input element.
                            var input = document.createElement('input');
                            input.type = 'file';
                            input.id = recordId;
                            input.accept = "video/*";

                            input.onchange = function() {
                                if (this.files && this.files[0]) {
                                    if (!this.files[0].size) {
                                        return;
                                    }

                                    var model = this;
                                    return dialogForUploadVideo(model.files);
                                }
                            };

                            document.body.appendChild(input);

                            // Click on a virtual input element.
                            input.click();

                            // return false;
                        };

                        function dialogForUploadVideo(files) {
                            return $mdDialog.show({
                                parent: angular.element(document.body),
                                multiple: true,
                                template:
                                    '<md-dialog aria-label="Video Upload Progress">'+
                                        '<md-dialog-content>'+
                                            '<div style="margin: 10px;">'+
                                                '<div layout="row">'+
                                                    '<div flex="none">{{ data.statusText }}</div>'+
                                                    '<div flex="flex"></div>'+
                                                    '<div ng-if="data.hasProgress" flex="none" class="percent" ng-class="{\'completed\': data.uploadCompleted }">{{data.percentageUploaded}}%</div>'+
                                                '</div>'+
                                                '<md-progress-linear ng-if="!data.hasProgress" class="loading-progress" md-mode="indeterminate"></md-progress-linear>'+
                                                '<md-progress-linear ng-if="data.hasProgress" class="loading-progress" md-mode="determinate" value="{{data.percentageUploaded}}" ></md-progress-linear>'+
                                            '</div>'+
                                        '</md-dialog-content>'+
                                    '</md-dialog>',
                                onComplete: function($scope, $element) {
                                    $scope.data = {
                                        uploadCompleted: false,
                                        percentageUploaded: 0,
                                        hasProgress: true,
                                        statusText: $translate.instant('editor.inProgressText'),
                                    };

                                    function progress(evt) {
                                        $scope.data.percentageUploaded = evt.$progress.percentageUploaded;
                                        if ($scope.data.percentageUploaded >= 100) {
                                            $scope.data.uploadCompleted = true;
                                            $scope.data.statusText = "Processing Video..";
                                            $scope.data.hasProgress = false;
                                        }
                                    }

                                    newUploadManager.upload(files, { url: '/files/upload-file-media', progress: progress, fields: {folder: state} }).then(function success(response) {
                                        $scope.data.uploadCompleted = true;
                                        $scope.data.posterUrl = response[0].uploadedUrl;
                                        $scope.data.statusText = $translate.instant('editor.completedText');
                                        $scope.data.shareId = response[0].shareId;
                                        $rootScope.$broadcast('imgeditor.completed');
                                    }, function failure(data) {
                                        if (typeof data === 'object' && data !== null) {
                                            utils.showToast(data['file'][0]);
                                        } else {
                                            utils.showToast(data);
                                        }
                                    }).finally(function() {
                                        $('#'+recordId).remove();
                                        $mdDialog.hide({ posterUrl: $scope.data.posterUrl, shareId: $scope.data.shareId });
                                    });
                                }
                            }).then(function(data) {
                                $mdDialog.hide(data);
                            }, function() {
                                //handle error
                            });
                        }

                        $scope.browseVideo = function() {
                            $scope.data.folder = null;
                            $scope.data.breadcrumbs = [];
                            $scope.getFolder('root');
                            $scope.data.state = 'browse';
                        };

                        $scope.getFolder = function(shareId) {
                            api.folder.getFolder(shareId).then(function (response) {
                                var validFiles = [];

                                response.folder.files.forEach(function (item) {
                                    if (item.mime.includes('video')) {
                                        validFiles.push(item);
                                    }
                                });

                                response.folder.files = validFiles;
                                $scope.data.folder = response;

                                var index = $scope.data.breadcrumbs.findIndex(
                                    function (element) {
                                        return (element.shareId == shareId);
                                    });

                                if (index >= 0) {
                                    $scope.data.breadcrumbs.length = index;
                                }

                                if (shareId == 'root') {
                                    $scope.data.breadcrumbs = [{
                                        'name': response.folder.name,
                                        'shareId': response.folder.share_id
                                    }];
                                }

                                if ($scope.data.breadcrumbs[$scope.data.breadcrumbs.length - 1]['shareId'] != response.folder.share_id) {
                                    $scope.data.breadcrumbs.push({
                                        'name': response.folder.name,
                                        'shareId': response.folder.share_id
                                    });
                                }
                            });
                        };

                        $scope.backToMainMenu = function() {
                            $scope.data.state = 'menu';
                        };

                        $scope.getMimeIcon = function(mime) {
                            return fileTypes.getMimeIcon(mime);
                        };

                        $scope.getIsThumbnail = function(item) {
                            return item.flags & 0x02 ? true : false;
                        };

                        $scope.getThumbnail = function(item) {
                            return $rootScope.baseUrl+'user-file/'+item.share_id+'?thumb=1&t='+ $scope.data.time;
                        };

                        $scope.setUrl = function (item) {
                            $scope.data.posterUrl = item.poster_url;
                            $scope.data.shareId = item.share_id;
                        };
                    }]
                });
            },

            showEditOrganizationsDialog: function(event, item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/edit-organization.html',
                    clickOutsideToClose: false,
                    locals: {
                        item: item
                    },
                    targetEvent: event,
                    controller: ['$scope', 'item', '$mdDialog', 'api', '$q', 'users', function($scope, item, $mdDialog, api, $q, users) {
                      $scope.data = {
                        isCreate: !item ? true : false,
                        name: item ? item.name : '',
                        website_url: item ? item.website_url : '',
                        logo_url: item ? item.logo_url : '',
                        address_1: item ? item.address_1 : '',
                        address_2: item ? item.address_2 : '',
                        city: item ? item.city : '',
                        state: item ? item.state : '',
                        zip: item ? parseInt(item.zip) : null,
                        phone: item ? item.phone : '',
                        approved_or_rejected_user_id: item ? item.approved_or_rejected_user_id : '',
                        approval_date: item ? item.approval_date : '',
                        created_date: item ? item.created_at : '',
                        rejection_date: item ? item.rejection_date : '',
                        submitted_date: item ? item.submitted_date : '',
                        manager_user_name: item ? item.manager_user_name : '',
                        is_parent: item && item.is_parent ?  true : false,
                        contact_first_name: item ? item.contact_first_name : '',
                        contact_last_name: item ? item.contact_last_name : '',
                        contact_phone: item ? item.contact_phone : '',
                        contact_email: item ? item.contact_email : '',
                        is_nonprofit: item && !item.is_nonprofit ? false : true,
                        per_user_charge: item ? parseFloat(item.per_user_charge) : 100.0,
                        searchText: '',
                        owner: (item && item.owner_id) ? getOwnerObectFromItem(item) : '',
                        is_admin: users.current.isAdmin
                      };

                      function getOwnerObectFromItem(item) {
                        var user = {
                          first_name: item.contact_first_name,
                          last_name: item.contact_last_name,
                          email: item.contact_email,
                          phone: item.contact_phone,
                          full_name: item.owner_name,
                          owner_id: item.owner_id
                        };
                        return user;
                      }

                      $scope.save = function() {
                          var result = {
                              item: item
                          };

                          angular.forEach($scope.data, function(value, key) {
                            result[key] = value;
                          });
                          $mdDialog.hide(result);
                      };

                      $scope.close = function() {
                          $mdDialog.cancel();
                      };

                      $scope.setOwner = function(user) {
                        if (user) {
                          $scope.data.owner = user;
                          $scope.data.contact_first_name = user.first_name;
                          $scope.data.contact_last_name = user.last_name;
                          $scope.data.contact_email = user.email;
                          $scope.data.contact_phone = user.phone;
                        } else {
                          $scope.data.owner = null;
                          $scope.data.contact_first_name = '';
                          $scope.data.contact_last_name = '';
                          $scope.data.contact_email = '';
                          $scope.data.contact_phone = '';
                        }
                      };

                      $scope.getNotFoundText = function() {
                          return $scope.data.searchText ? 'No users matching '+ $scope.data.searchText +' were found.' : 'You need to find owner using first name, last name or email address';
                      };

                      $scope.querySearch = function(query) {
                        return $q(function(resolve, reject) {
                          if(!query) {
                            resolve([]);
                          } else {
                            api.user.searchUser(query).then(function(data) {
                              data = data.filter(function(user) {
                                return !user.is_admin;
                              });
                              resolve(data);
                            }, reject);
                          }
                        });
                      };

                    }]
                });
            },

            showEditOrganizationJoinCodeDialog: function(event, item) {
              return $mdDialog.show({
                  templateUrl: 'assets/views/modals/edit-join-code-organization.html',
                  clickOutsideToClose: true,
                  locals: {
                      item: item
                  },
                  targetEvent: event,
                  controller: ['$scope', 'item', '$mdDialog', 'api', 'users', function($scope, item, $mdDialog, api, users) {
                    $scope.data = {
                      isCreate: !item ? true : false,
                      code: item ? item.code : '',
                      start: item ? item.start : '',
                      start_date: item ? item.start_date : '',
                      end_date: item ? item.end_date : '',
                      org_id: item ? item.organization_id : '',
                      orgs: [],
                      isOrgOwner: users.current && users.current.isOrgOwner,
                    };

                    var data = {};
                    if ($scope.data.isOrgOwner) {
                      $scope.data.org_id = users.current.organization_id;
                      data = {type: 'org_id', org_id: $scope.data.org_id};
                    }

                    api.resources.organization.query(data, function(orgs) {
                      $scope.data.orgs = orgs;
                    });

                    $scope.save = function() {
                        var result = {
                            item: item
                        };

                        angular.forEach($scope.data, function(value, key) {
                            result[key] = value;
                        });
                        $mdDialog.hide(result);
                    };

                    $scope.close = function() {
                        $mdDialog.cancel();
                    };
                  }]
              });
            },

            showOrganizationJoinDialog: function() {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/org-join-organization.html',
                    clickOutsideToClose: false,
                    targetEvent: event,
                    controller: ['$scope', '$mdDialog', '$translate', 'api', function($scope, $mdDialog, $translate, api) {
                      $scope.data = {
                        coupon_code: '',
                        org_id: '',
                        org_name: '',
                        error:'',
                        orgs: [],
                      };

                      $scope.data.orgs = api.resources.organization.query({filter: 'only_parents'});

                      $scope.save = function() {
                        $scope.data.error = '';
                        var result = {};
                        result['code'] = $scope.data.coupon_code;
                        result['org_id'] = $scope.data.org_id;
                        api.organization.validateJoinCode(result).then(function(response) {
                          result['is_org'] = 1;
                          result['org_name'] = $scope.data.org_name;
                          $mdDialog.hide(result);
                        }, function(response) {
                          $scope.data.error = $translate.instant(response.data);
                        });
                      };

                      $scope.normalRegister = function() {
                        $mdDialog.hide(null);
                      };

                      $scope.close = function() {
                          $mdDialog.cancel();
                      };
                    }]
                });
            },

            showOrganizationMemberDialog: function(switchAccount) {
                switchAccount = switchAccount || false;
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/member-join-organization.html',
                    clickOutsideToClose: false,
                    targetEvent: event,
                    controller: ['$scope', '$mdDialog', '$translate', 'api', function($scope, $mdDialog, $translate, api) {
                      $scope.data = {
                        coupon_code: '',
                        contact_phone: '',
                        contact_last_name: '',
                        contact_first_name: '',
                        contact_email: '',
                        org_id: '',
                        haveCode: false,
                        error:'',
                        orgs: [],
                        type: '',
                        switchAccount: switchAccount ? true : false,
                      };

                      $scope.data.orgs = api.resources.organization.query({filter: 'no_parents'});

                      $scope.save = function() {
                        $scope.data.error = '';
                        var result = {};
                        if ($scope.data.type == 'code') {
                          result['code'] = $scope.data.coupon_code;
                          result['org_id'] = $scope.data.org_id;
                          api.organization.validateJoinCode(result).then(function(response) {
                            $mdDialog.hide(result);
                          }, function(response) {
                            $scope.data.error = $translate.instant(response.data);
                          });
                        } else {
                          result['contact_phone'] = $scope.data.contact_phone;
                          result['contact_last_name'] = $scope.data.contact_last_name;
                          result['contact_first_name'] = $scope.data.contact_first_name;
                          result['contact_email'] = $scope.data.contact_email;
                          result['org_id'] = $scope.data.org_id;
                          $mdDialog.hide(result);
                        }
                      };

                      $scope.normalRegister = function() {
                        $mdDialog.hide(null);
                      };

                      $scope.close = function() {
                          $mdDialog.cancel();
                      };
                    }]
                });
            },

           showEditFooterMenuDialog: function(event, item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/edit-footermenu.html',
                    clickOutsideToClose: false,
                    locals: {
                        item: item
                    },
                    targetEvent: event,
                    controller: ['$scope', 'api', 'item', '$mdDialog', '$rootScope', 'utils', 'textAngularManager', '$timeout', function($scope, api, item, $mdDialog, $rootScope, utils, textAngularManager, $timeout) {
                        $scope.data = {
                          item : item
                        };

                        $scope.add = function() {
                          api.footermenu.addMenuItem($scope.data.item).then(function(data) {
                            $mdDialog.hide(item);
                          });
                        };

                        $scope.update = function() {
                          api.footermenu.updateMenuItem($scope.data.item).then(function(data) {
                            $mdDialog.hide(item);
                          });
                        };

                        $scope.close = function() {
                            $mdDialog.cancel();
                        };

                        function editorFocus() {
                          $timeout(function() {
                            var editorScope = textAngularManager.retrieveEditor('editor').scope;
                            if (editorScope) {
                                editorScope.displayElements.text.trigger('focus');
                            }
                          }, 5);
                        }

                        editorFocus();
                    }]
                });
            },

            showAcceptExecutorDialog: function(executor_account, executor_hash) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/accept-executor.html',
                    clickOutsideToClose: true,
                    controller: ['$scope', '$http', 'utils', function($scope, $http, utils) {
                        $scope.data = {
                          executor_account: executor_account
                        };

                        $scope.accept = function() {

                            // This could be in service, instead
                            $http.post('executor/accept', { hash: executor_hash }).then(function(response) {
                                utils.showToast(utils.trans('executorSuccess', $scope.data.executor_account.user));
                                $mdDialog.hide(response.data);
                            }, function(response) {
                                utils.showToast(response.data, true);
                            });
                        };

                        $scope.close = function() {
                            $mdDialog.cancel();
                        };

                        $scope.capitalize = function(input) {
                            return function(input, scope) {
                                if ( input != null ) {
                                    input = input.toLowerCase();
                                }
                                return input.substring(0,1).toUpperCase()+input.substring(1);
                            };
                        };
                    }]
                });
            },

            showCreateUserDialog: function(event) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/create-user.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'utils', 'users', function($scope, utils, users) {

                  $scope.data = {
                    userModel: {},
                    errors: []
                  };

                  $scope.createNewUser = function() {
                    users.register($scope.data.userModel).then(function() {
                        utils.showToast('createdUserSuccessfully', true);
                        $mdDialog.hide();
                    }, function(failure) {
                      setErrors(failure.data);
                    });
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  function setErrors(errors) {
                    $scope.data.errors = [];
                    if (angular.isString(errors)) {
                      return utils.showToast(errors);
                    }

                    //otherwise append each error
                    for (var field in errors) {
                      $scope.data.errors.push(errors[field][0]);
                    }
                  }
                }],
                targetEvent: event,
              });
            },

            showUpdateUserDialog: function(event, user) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/update-user.html',
                clickOutsideToClose: true,
                locals: {
                  user: user
                },
                controller: ['$scope', 'utils', 'user', 'api', function($scope, utils, user, api) {
                  $scope.data = {
                    userModel: angular.copy(user),
                    errors  : [],
                    loading: false
                  };

                  delete $scope.data.userModel.password;
                  $scope.data.userModel.created_at = $scope.data.userModel.created_at.split("T")[0];
                  
                  $scope.updateUser = function() {
                    $scope.data.loading = true;
                    api.user.updateSettings($scope.data.userModel.id, $scope.data.userModel).then(function() {
                        $mdDialog.hide();
                        utils.showToast('updatedUserSuccessfully', true);
                        $scope.data.loading = false;
                    }, function(failure) {
                        setErrors(failure);
                    });
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  $scope.isFormUpdated = function() {
                    var changed = false;

                    if ($scope.data.userModel.email && $scope.data.userModel.email != user.email) {
                      return true;
                    }

                    if ($scope.data.userModel.username && $scope.data.userModel.username != user.username) {
                      return true;
                    }

                    if ($scope.data.userModel.first_name && $scope.data.userModel.first_name != user.first_name) {
                      return true;
                    }

                    if ($scope.data.userModel.first_name && $scope.data.userModel.first_name != user.first_name) {
                      return true;
                    }

                    if ($scope.data.userModel.last_name != user.last_name) {
                      return true;
                    }

                    if ($scope.data.userModel.password && $scope.data.userModel.password != user.password && $scope.data.userModel.password == $scope.data.userModel.password_confirmation) {
                      return true;
                    }

                    if ($scope.data.userModel.password_confirmation && $scope.data.userModel.password_confirmation != user.password_confirmation && $scope.data.userModel.password == $scope.data.userModel.password_confirmation) {
                      return true;
                    }

                    if ($scope.data.userModel.gender && $scope.data.userModel.gender != user.gender) {
                      return true;
                    }

                    if ($scope.data.userModel.space_available && $scope.data.userModel.space_available != user.space_available) {
                      return true;
                    }

                    return changed;
                  };

                  function setErrors(errors) {
                    $scope.data.errors = [];
                    if (angular.isString(errors)) {
                      return utils.showToast(errors);
                    }

                    //otherwise append each error
                    for (var field in errors) {
                      $scope.data.errors.push(errors[field][0]);
                    }
                  }
                }],
                targetEvent: event,
              });
            },

            showAddTopicDialog: function(sectionId, item) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/edit-topic-modal.html',
                clickOutsideToClose: true,
                targetEvent: event,
                controller: ['$scope', 'utils', 'api', function($scope, utils, api) {
                  $scope.data = {
                    topic : item ? item.topic : '',
                    create : !item ? true : false,
                  };

                  $scope.updateTopic = function() {
                    if ($scope.data.create) {
                      api.storyTopic.add(sectionId, $scope.data.topic).then(function(topic) {
                        utils.showToast(utils.trans('topic.addedSuccessfully', true));
                        $mdDialog.hide(topic);
                      }, function(response) {
                        utils.showToast(response.data);
                        // handle
                      });
                    } else {
                      api.storyTopic.edit(sectionId, item.id, $scope.data.topic).then(function(topic) {
                        utils.showToast(utils.trans('topic.updatedSuccessfully', true));
                        $mdDialog.hide(topic);
                      }, function(response) {
                        utils.showToast(response.data);
                        // handle
                      });
                    }
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };
                }]
              });
            },

            showNewFolderDialog: function(event, selected) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/new-folder-name-modal.html',
                clickOutsideToClose: true,
                targetEvent: event,
                controller: ['$scope', 'folders', 'utils', function($scope, folders, utils) {
                  // $scope.data.folders = folders;
                  $scope.data = {
                    folderNameModel : {},
                    create          : true,
                    errors          : []
                  };

                  $scope.createNewFolder = function() {
                    if ( ! $scope.data.folderNameModel.new) {
                      return;
                    }

                    folders.createNewFolderWithParams($scope.data.folderNameModel.new, selected).then(function(data) {
                      $mdDialog.hide(data.share_id);
                      utils.showToast('newFolderCreated', true);
                      // $('.folder-name-modal .md-modal-error').html('');
                    }, function(failure) {
                      setErrors(failure.data);
                      // $('.folder-name-modal .md-modal-error').html(response.data);
                    });
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  function setErrors(errors) {
                    $scope.data.errors = [];
                    if (angular.isString(errors)) {
                      return utils.showToast(errors);
                    }

                    //otherwise append each error
                    for (var field in errors) {
                      $scope.data.errors.push(errors[field][0]);
                    }
                  }
                }],
                onComplete: function() {
                  $('#folder-name').focus();
                }
              });
            },

            showCreateQuestionDialog: function(event) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/create-question.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'utils', 'questions', function($scope, utils, questions) {
                  $scope.data = {
                    questionModel: {},
                    errors       : []
                  };

                  $scope.createNewQuestion = function() {
                      questions.create($scope.data.questionModel).then(function() {
                          utils.showToast('createdQuestionSuccessfully', true);
                          $mdDialog.hide();
                      }, function(failure) {
                        setErrors(failure.data);
                      });
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  function setErrors(errors) {
                    $scope.data.errors = [];
                    if (angular.isString(errors)) {
                      return utils.showToast(errors);
                    }

                    //otherwise append each error
                    for (var field in errors) {
                      $scope.data.errors.push(errors[field][0]);
                    }
                  }
                }],
                targetEvent: event,
              });
            },

            showCreateSectionDialog: function(event) {
              return dialogs.showUpdateSectionDialog(event, null);
            },

            showUpdateSectionDialog: function(event, section) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/model-section.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'utils', 'storySections', function($scope, utils, storySections) {
                  $scope.data = {
                    section: section ? angular.copy(section) : {},
                    errors: [],
                    isCreate: section ? false : true
                  };


                  $scope.updateSection = function() {
                    storySections.update($scope.data.section, $scope.data.section.id).then(function() {
                      $mdDialog.hide();
                      utils.showToast('updatedQuestionSuccessfully', true);
                    }, function(failure) {
                        setErrors(failure.data);
                    });
                  };

                  $scope.createNewSection = function() {
                    storySections.create($scope.data.section).then(function() {
                          utils.showToast('createdQuestionSuccessfully', true);
                          $mdDialog.hide();
                      }, function(failure) {
                        setErrors(failure.data);
                      });
                  };

                  $scope.save = function() {
                    if($scope.data.isCreate) {
                      $scope.createNewSection();
                    } else {
                      $scope.updateSection();
                    }
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  function setErrors(errors) {
                    $scope.data.errors = [];
                    if (angular.isString(errors)) {
                      return utils.showToast(errors);
                    }

                    //otherwise append each error
                    for (var field in errors) {
                      $scope.data.errors.push(errors[field][0]);
                    }
                  }
                }],
                targetEvent: event,
              });
            },

            showCreateTopicDialog: function(event, sections) {
              return dialogs.showUpdateTopicDialog(event, null, sections);
            },

            showUpdateTopicDialog: function(event, topic, sections) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/model-topic.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'utils', 'storyTopics', function($scope, utils, storyTopics) {
                  $scope.data = {
                    topic: topic ? angular.copy(topic) : {},
                    errors: [],
                    isCreate: topic ? false : true,
                    sections: sections
                  };


                  $scope.updateTopic = function() {
                    storyTopics.update($scope.data.topic, $scope.data.topic.id).then(function() {
                      $mdDialog.hide();
                      utils.showToast('updatedQuestionSuccessfully', true);
                    }, function(failure) {
                        setErrors(failure.data);
                    });
                  };

                  $scope.createNewTopic = function() {
                    storyTopics.create($scope.data.topic).then(function() {
                          utils.showToast('createdQuestionSuccessfully', true);
                          $mdDialog.hide();
                      }, function(failure) {
                        setErrors(failure.data);
                      });
                  };

                  $scope.save = function() {
                    if($scope.data.isCreate) {
                      $scope.createNewTopic();
                    } else {
                      $scope.updateTopic();
                    }
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  function setErrors(errors) {
                    $scope.data.errors = [];
                    if (angular.isString(errors)) {
                      return utils.showToast(errors);
                    }

                    //otherwise append each error
                    for (var field in errors) {
                      $scope.data.errors.push(errors[field][0]);
                    }
                  }
                }],
                targetEvent: event,
              });
            },


            showNewDocDialog: function(event, folder_id) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/new-doc-modal.html',
                clickOutsideToClose: true,
                targetEvent: event,
                controller: ['$scope', 'files', 'folders', 'utils', 'api', 'textAngularManager', '$timeout', function($scope, files, folders, utils, api, textAngularManager, $timeout) {
                  $scope.data = {
                    content  : '',
                    file_name: '',
                    privacy: '',
                    creating: false,
                  };

                  $scope.saveNewFile = function() {
                    $scope.data.creating = true;
                    api.file.createFile({content: $scope.data.content, folder_id: folder_id, file_name: $scope.data.file_name, privacy: $scope.data.privacy}).then(function(response) {
                      var folder = folders.getById(response.folder_id);

                      if ( folder && folder.files) {
                        folder.files.push(response);
                      }

                      $mdDialog.hide(response);
                      utils.showToast('fileCreated', true);
                    }, function(response) {
                      utils.showToast(response);
                    }).finally(function() {
                      $scope.data.creating = false;
                    });
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  function editorFocus() {
                    $timeout(function() {
                      var editorScope = textAngularManager.retrieveEditor('editor').scope;
                      if (editorScope) {
                        editorScope.displayElements.text.trigger('focus');
                      }
                    }, 5);
                  }

                  editorFocus();

                  $scope.$on('imgeditor.completed', function() {
                    $timeout(function() {
                      var editorScope = textAngularManager.retrieveEditor('editor').scope;
                      if (editorScope) {
                        $scope.data.content = editorScope.html;
                      }
                    }, 10);
                  });
                }]
              });
            },

            showEditDocDialog: function(content, share_id) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/edit-html-modal.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'files', 'selectedItems', '$http', '$rootScope', 'folders', 'utils', '$timeout', 'textAngularManager', function($scope, files, selectedItems, $http, $rootScope, folders, utils, $timeout, textAngularManager) {
                    $scope.data = {
                      content: content,
                      saving: false,
                    };

                  $scope.updateHtmlFile = function() {
                    $scope.data.saving = true;
                    $http.post($rootScope.baseUrl + 'files/edit-file', {content: $scope.data.content, share_link: share_id}).then(function success(response) {
                      var folder = folders.getById(response.data.folder_id);

                      if ( ! folder || ! folder.files) {
                          $mdDialog.hide(response.data);
                          utils.showToast('fileEdited', true);
                      } else {
                          for (var i = 0; i < folder.files.length; i++) {
                            if (folder.files[i].id == response.data.id) {
                                folder.files[i] = response.data;
                                $mdDialog.hide(response.data);
                                utils.showToast('fileEdited', true);
                            }
                          }
                      }
                    }, function error(response) {
                        utils.showToast(response.data);
                    }).finally(function() {
                      $scope.data.saving = false;
                    });
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  function editorFocus() {
                    $timeout(function() {
                      var editorScope = textAngularManager.retrieveEditor('editor').scope;
                      if (editorScope) {
                        editorScope.displayElements.text.trigger('focus');
                      }
                    }, 5);
                  }

                  editorFocus();

                  $scope.$on('imgeditor.completed', function() {
                    $timeout(function() {
                      var editorScope = textAngularManager.retrieveEditor('editor').scope;
                      if (editorScope) {
                        $scope.data.content = editorScope.html;
                      }
                    }, 10);
                  });

                }]
              });
            },

            showEditInvoiceFileNameDialog: function(fileName) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/edit-invoice-filename.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                controller: ['$scope', function($scope) {
                  $scope.data = {
                    fileName : fileName
                  };

                  $scope.renameCsv = function() {
                    $mdDialog.hide($scope.data.fileName);
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                }]
              });
            },

            showRenameFolderDialog: function(folder) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/new-folder-name-modal.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'folders', 'utils', function($scope, folders, utils) {
                  $scope.data = {
                    folderNameModel : {new: folder.name, old: folder.name, id: folder.id},
                    rename          : true
                  };

                  $scope.renameFolder = function() {
                    var folder  = folders.getById($scope.data.folderNameModel.id);
                    var payload = { name: $scope.data.folderNameModel.new };

                    if (folder && folder.name !== 'root') {
                      folders.rename(folder, payload).then(function success(response) {
                        utils.showToast('folderRenameSuccess', true);
                        $mdDialog.hide(response.data);
                      }, function error(failure) {
                        utils.showToast(failure.data);
                      });
                    } else {
                      utils.showToast(utils.trans('folderNameInvalid', true));
                    }
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                }],
                onComplete: function() {
                  $('#folder-name').focus();
                }
              });
            },

            showUpdateQuestionDialog: function(event, question) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/update-question.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'utils', 'questions', function($scope, utils, questions) {
                  $scope.data = {
                    questionModel: angular.copy(question),
                    errors: []
                  };

                  delete $scope.data.questionModel.password;

                  $scope.updateQuestion = function() {
                      questions.updateQuestion($scope.data.questionModel, $scope.data.questionModel.id).then(function() {
                        $mdDialog.hide();
                        utils.showToast('updatedQuestionSuccessfully', true);
                      }, function(failure) {
                          setErrors(failure.data);
                      });
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  function setErrors(errors) {
                    $scope.data.errors = [];
                    if (angular.isString(errors)) {
                      return utils.showToast(errors);
                    }

                    //otherwise append each error
                    for (var field in errors) {
                      $scope.data.errors.push(errors[field][0]);
                    }
                  }
                }],
                targetEvent: event,
              });
            },

            showPostToTimelineDialog: function(item) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/post-to-timeline.html',
                controller: ['$scope','api', 'utils', function($scope, api, utils) {
                  $scope.data = {
                    selectedPrivacy: [],
                    selectedUser: [],
                    postType: 'private',
                    postText: '',
                    options: [],
                    loading: true,
                    posting: false,
                    comment: '',
                  };

                  var post = new api.resources.post();
                  post.file_id = item.id;
                  post.user_id = item.user_id;

                  function loadOptions(options) {
                    options.forEach(function(opt) {
                      opt.text = opt.name;
                      opt.user = false;
                      if ('users' in opt) {
                        opt.children = [];
                        opt.collapsed = true;
                        opt.users.forEach(function(user) {
                          var child = {};
                          child.id = user.id;
                          child.text = user.name;
                          child.user = true;
                          child.type = opt.type;
                          opt.children.push(child);
                        });
                      }
                    });
                    return options;
                  }

                  api.me.privacyOptions().then(function(options) {
                    $scope.data.options = angular.copy(loadOptions(options));
                  }).finally(function() {
                    $scope.data.loading = false;
                  });

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  $scope.postToTimeline = function() {
                    if ($scope.data.posting) {
                      return;
                    }

                    $scope.data.posting = true;
                    post.users = $scope.data.selectedUser;
                    post.privacy = $scope.data.selectedPrivacy;
                    post.$save().then(function(data) {
                      utils.showToast(utils.trans('post.filePostedSuccessful', true));
                      if (!$scope.data.comment) {
                        $mdDialog.hide();
                      } else {
                        api.item.addComment({ comment: $scope.data.comment, parent: 0, item_id: data.id, type: 'post'}).then(function(data) {
                          utils.showToast('commentPosted', true);
                          $mdDialog.hide();
                        });
                      }
                    }, function() {
                      utils.showToast(utils.trans('genericError', true));
                      $scope.data.posting = false;
                    });
                  };
                }]
              });
            },

            showQuestionDialog: function(question) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/question-answer.html',
                    clickOutsideToClose: false,
                    controller: ['$scope', 'question', 'folders', '$q', 'users', 'utils', '$mdDialog', 'uploadManager', '$rootScope', function($scope, question, folders, $q, users, utils, $mdDialog, uploadManager, $rootScope) {
                        $scope.data = {
                            question: question,
                            answer: '',
                            folderUploadSupported: utils.folderUploadSupported
                        };

                        $scope.close = function(){
                          $mdDialog.hide();
                        };

                        function createOrLoadPerspectiveFolder() {
                            return $q(function(resolve, reject) {
                                var myFamilyPerspectiveFolder = folders.getByClasstype("perspective");
                                if(myFamilyPerspectiveFolder) {
                                    resolve(myFamilyPerspectiveFolder);
                                } else {
                                    var rootFolder = folders.getByClasstype("root");
                                    folders.createNewFolderWithParams("My Family Perspective", rootFolder, 'perspective', 1).then(function(folder) {
                                        resolve(folder);
                                    }, function(failure) {
                                        reject(failure);
                                    });
                                }
                            });
                        }

                        $scope.submitAnswer = function() {
                            // this should really create the file locally and upload it.
                            createOrLoadPerspectiveFolder().then(function(myFamilyPerspectiveFolder) {
                                users.submitAnswer({ answer: $scope.data.answer, questionId: $scope.data.question.id, folderId: myFamilyPerspectiveFolder.id }).then(function(response) {
                                    $rootScope.$broadcast('activity.happened', 'uploaded', 'files', { name: $scope.data.question.question, id: response.data.file_id, folder_id: myFamilyPerspectiveFolder.id, mime: 'text/plain'});

                                    $mdDialog.hide({ folder: myFamilyPerspectiveFolder, is_video: false });
                                }, function(response) {
                                    utils.showToast(response.data);
                                });

                            }, function(failure) {
                                utils.showToast(failure.data);
                            });
                        };

                        $scope.uploadFiles = function(files) {
                            if(files && files.length && files[0].type.indexOf('video') < 0) {
                                utils.showToast("Upload support only video");
                            } else if(files && files.length){
                                // upload file with some other name
                                files[0].newName = String($scope.data.question.question).replace(/<[^>]+>/gm, '')+"."+files[0].name.split('.')[1];

                                createOrLoadPerspectiveFolder().then(function(myFamilyPerspectiveFolder) {
                                    if(myFamilyPerspectiveFolder.files === undefined) {
                                        myFamilyPerspectiveFolder.files = [];
                                    }

                                    folders.selected = myFamilyPerspectiveFolder;
                                    uploadManager.upload(files, 'perspectiveSpecial');

                                    // at this point we can go away...
                                    $mdDialog.hide({ folder: myFamilyPerspectiveFolder, is_video: true });

                                    // not sure if this makes sense to do or not...
                                    // $scope.folders.open(myFamilyPerspectiveFolder.share_id);
                                }, function(failure) {
                                    utils.showToast(failure.data);
                                });
                            }
                        };

                        $scope.$on('perspectiveSpecial', function($event, files) {
                            users.submitVideoAnswer({ fileId: files[0].id, questionId: $scope.data.question.id }).then(function(response) {
                                createOrLoadPerspectiveFolder().then(function(myFamilyPerspectiveFolder) {
                                    $mdDialog.hide({ folder: myFamilyPerspectiveFolder, is_video: true });
                                });
                            }, function(response) {
                                utils.showToast(response.data);
                            });
                        });

/*
                        var familyPerspectiveWorkSubmit = function(myFamilyPerspectiveFolder, filesToUpload) {
                            if(myFamilyPerspectiveFolder.files === undefined) {
                                myFamilyPerspectiveFolder.files = [];
                            }

                            $scope.folders.selected = myFamilyPerspectiveFolder;
                            $scope.upload(filesToUpload, 'perspectiveSpecial');
                            $scope.folders.open(myFamilyPerspectiveFolder.share_id);
                        };

                        var folderExistsOrCreate = function(cb) {
                            // check perspective folder exist?
                            var myFamilyPerspectiveFolder = $scope.folders.getByClasstype("perspective");
                            if(myFamilyPerspectiveFolder) {
                                cb(myFamilyPerspectiveFolder);
                            } else {
                                $scope.folders.folderNameModel.new = "My Family Perspective";
                                $scope.folders.folderNameModel.classtype = "perspective";
                                $scope.folders.folderNameModel.flags = 1;
                                $scope.folders.createNewFolderJS(function(myFamilyPerspectiveFolder){
                                    cb(myFamilyPerspectiveFolder);
                                });
                            }
                        };
*/

                    }],
                    locals: {
                        question: question
                    }
                });
            },

            showGetRoomDialog: function() {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/get-room-video.html',
                clickOutsideToClose: true,
                controller: ['$scope', '$mdDialog', 'api', '$state', function($scope, $mdDialog, api, $state) {

                  $scope.data = {
                    title: "startRoomDialog",
                    okButton: "start",
                    roomName: '',
                    loading: false
                  };

                  $scope.startRoom = function() {
                    if ($scope.data.roomName && !$scope.data.loading) {
                      $scope.data.loading = true;
                      $state.go('dashboard.rooms', { roomId: $scope.data.roomName });
                      $scope.close();
                    }
                  };

                  $scope.close = function() {
                      $mdDialog.cancel();
                  };
                }]
              });
            },

            showShareDialog: function(event, item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/share.html',
                    controller: ['$scope', '$rootScope', '$translate', '$mdDialog', '$http', 'files', 'utils', 'item', 'folders', 'api', '$q', 'users', function($scope, $rootScope, $translate, $mdDialog, $http, files, utils, item, folders, api, $q, users) {
                        function buildLink(item) {
                            return $rootScope.baseUrl+( ! utils.getSetting('enablePushState') ? '#/' : '')+'view/'+item.type+'/'+item.share_id + '/' + item.name;
                        }

                        $scope.data = {
                            emails: [],
                            hives: [],
                            phones: [],
                            mailSendInProgress: false,
                            sentMessage: undefined,
                            shareablePermission: null,
                            item: item,
                            link: buildLink(item),
                            type: $translate.instant(item.type),
                            selectedItem: null,
                            searchText: "",
                            canShareWithEditPermission: true,
                            selectedPrivacy: [],
                            selectedUser: [],
                            options: [],
                            defaultOptions: [],
                            privacyChanged: false
                        };

                        if (users.current && users.current.permissions && ('is_nonprofit' in users.current.permissions || 'org_owner' in users.current.permissions)) {
                            if (users.current.permissions.is_nonprofit) {
                              $scope.data.canShareWithEditPermission = false;
                              $scope.data.shareablePermission = 'view';
                            }
                        }

                        $scope.utils = utils;

                        // required for ShareIcons (poorly factored)
                        $scope.shareable = item;

                        function familyToAutoComplete(familyMembers) {
                          var members = [];

                          angular.forEach(familyMembers, function(value, key){
                            members.push({
                              email: value.email,
                              text: users.getName(value) + ' (' + value.email +')',
                              avatar_url: value.avatar_url,
                            });
                          });
                          return members;
                        }

                        function hiveToAutoComplete(groups) {
                          var hives = [];

                          angular.forEach(groups, function(value, key){
                            hives.push({
                              name: value.name,
                              text: value.name,
                              id: value.id,
                            });
                          });
                          return hives;
                        }

                        $scope.loadFamily = function(query) {
                          return $q(function(resolve, reject) {
                            if(!query) {
                              resolve([]);
                            } else {
                              api.me.familyMembersSuggestion(query).then(function(response) {
                                resolve(familyToAutoComplete(response));
                              }, reject);
                            }
                          });
                        };

                        // $scope.loadHiveGroups = function(query) {
                        //   return $q(function(resolve, reject) {
                        //     if(!query) {
                        //       resolve([]);
                        //     } else {
                        //       api.me.hiveGroupsSuggestion(query).then(function(response) {
                        //         resolve(hiveToAutoComplete(response));
                        //       }, reject);
                        //     }
                        //   });
                        // };

                        // function removeOption(type, option) {
                        //   var idx = type.indexOf(option.id);
                        //   if (idx != -1) {
                        //     type.splice(idx, 1);
                        //     if (option.type == 'default' && option.user == false) {
                        //       myHiveSelected = -1;
                        //     }
                        //   }
                        // }

                        // function addOption(type, option) {
                        //   var idx = type.indexOf(option.id);
                        //   if (idx == -1) {
                        //     if (option.type == 'default' && option.user == false) {
                        //       myHiveSelected = option.id;
                        //     }
                        //     type.push(option.id);
                        //     privacyType = option.type;
                        //   }
                        // }

                        // function checkAllUserForMyHive() {
                        //   var selectedOpt = false;
                        //   $scope.data.options.forEach(function(opt) {
                        //     if ('children' in opt && opt.children.length) {
                        //       var allSelected = true;
                        //       opt.children.forEach(function(child) {
                        //         if ($scope.data.selectedUser.indexOf(child.id) == -1) {
                        //           allSelected = false;
                        //         }
                        //       });

                        //       if (allSelected) {
                        //         selectedOpt = opt;
                        //       }
                        //     }
                        //   });
                        //   return selectedOpt;
                        // }

                        // function clearOptions() {
                        //   $scope.data.selectedUser = [];
                        //   $scope.data.selectedPrivacy = [];
                        //   myHiveSelected = -1;
                        // }

                        // $scope.udpateSelectedPrivacyOption = function(option) {
                        //   var idx;
                        //   if (option.selected) {
                        //     if (option.type != privacyType) {
                        //       clearOptions();
                        //     }

                        //     if (option.user) {
                        //       addOption($scope.data.selectedUser, option);
                        //       var myHive = checkAllUserForMyHive();
                        //       if (myHive) {
                        //         addOption($scope.data.selectedPrivacy, myHive);
                        //       }
                        //     } else {
                        //       addOption($scope.data.selectedPrivacy, option);
                        //       if ('children' in option && option.children.length) {
                        //         option.children.forEach(function(child) {
                        //           addOption($scope.data.selectedUser, child);
                        //         });
                        //       }
                        //     }
                        //   } else {
                        //     if (option.user) {
                        //       removeOption($scope.data.selectedUser, option);
                        //       if (myHiveSelected != -1) {
                        //         removeOption($scope.data.selectedPrivacy, {id: myHiveSelected, type: 'default', user: false});
                        //       }
                        //     } else {
                        //       removeOption($scope.data.selectedPrivacy, option);
                        //       if ('children' in option && option.children.length) {
                        //         option.children.forEach(function(child) {
                        //           removeOption($scope.data.selectedUser, child);
                        //         });
                        //       }
                        //     }
                        //   }

                        //   if ($scope.data.selectedPrivacy.length || (myHiveSelected == -1 && $scope.data.selectedUser.length)) {
                        //     $scope.data.privacySelected = true;
                        //   } else {
                        //     $scope.data.privacySelected = false;
                        //   }
                        // };

                        $scope.privacySelectionDone = function() {
                          console.log('before: ', $scope.data.privacyChanged);
                        };

                        function loadOptions(options, removePrivate) {
                          removePrivate = removePrivate || false;
                          var removeIdx = -1;
                          options.forEach(function(opt, optIdx) {
                            opt.text = opt.name;
                            opt.user = false;

                            if (removePrivate && opt.type == 'private') {
                              removeIdx = optIdx;
                            }

                            if ('users' in opt) {
                              opt.children = [];
                              opt.collapsed = true;
                              opt.users.forEach(function(user) {
                                var child = {};
                                child.id = user.id;
                                child.text = user.name;
                                child.user = true;
                                child.type = opt.type;
                                opt.children.push(child);
                              });
                            }
                          });

                          if (removeIdx !== -1) {
                            options.splice(removeIdx, 1);
                          }

                          return options;
                        }

                        api.me.privacyOptions().then(function(options) {
                          $scope.data.defaultOptions = loadOptions(options, true);
                          $scope.data.options = angular.copy($scope.data.defaultOptions);
                          // $rootScope.$broadcast('options.changed', $scope.data.options);
                        });

                        $scope.addPassword = function() {
                            $mdDialog.cancel().then(function() {
                                dialogs.showLockDialog(item); // .openLockModal(item);
                            });
                        };

                        $scope.removePassword = function() {
                            $mdDialog.cancel().then(function() {
                                dialogs.showRemoveLockDialog(item);
                                // lockables.openRemoveLockModal(item);
                            });
                        };

                        $scope.close = function() {
                            $mdDialog.cancel();
                        };

                        $scope.sendEmail = function() {
                            $scope.data.mailSendInProgress = true;

                            var payload = {emails: $scope.data.emails.map(function(e) { return ('email' in e) ? e.email : e.text;  }), type: $scope.data.item.type, shareable_id: $scope.data.item.share_id, name: $scope.data.item.name, message: $scope.emailMessage, permission: $scope.data.shareablePermission};
                            payload['phones'] = $scope.data.phones.map(function(e) {
                              return e.text;
                            });
                            payload['hives'] = $scope.data.selectedPrivacy;
                            payload['users'] = $scope.data.selectedUser;

                            $http.post('shareable/add-users', payload).then(function(response) {
                                utils.showToast(response.data);
                                $rootScope.$broadcast('familyPanel.reload');
                                // TODO:
                                // Update sentMessage
                                $scope.data.sentMessage = "Sharing email sent to "+payload.emails+".";

                                // add to the activity..
                                $rootScope.$broadcast('activity.happened', 'shared', 'item', item);

                                // and close the dialog
                                $mdDialog.hide();

                                // and refresh to update the file list.
                                folders.refreshFiles();
                            },function() {
                                utils.showToast('genericError', true);
                            }).finally(function() {
                                $scope.data.mailSendInProgress = false;
                            });
                        };

                        $scope.resetLink = function() {
                            $scope.link = "Loading...";

                            var payload = { id: $scope.data.item.share_id, type: $scope.data.item.type };

                            $http.post('reset-link', payload).then(function(response) {
                                $scope.data.item.share_id = response.data;
                                $scope.link = buildLink($scope.data.item);

                                // because share icons are poorly factored, we have to do this when we change the shareable
                                $scope.shareable = item;
                                $scope.$emit('shareable.ready');
                            }, function() {
                                utils.showToast('genericError', true);
                                $scope.link = buildLink($scope.data.item);
                            });
                        };

                        $scope.closeSelectBox = function () {
                          $("md-backdrop").trigger ("click");
                        };

                        // function getSelectedPrivacyNames() {
                        //   var privacyNames = $scope.data.selectedPrivacy.map(function(privacy) {
                        //     return privacy.name;
                        //   });
                        //   var numberOfPrivacies = privacyNames.length;

                        //   if (numberOfPrivacies > 1) {
                        //     var needsOxfordComma = numberOfPrivacies > 2;
                        //     var lastPrivacyConjunction = (needsOxfordComma ? ',' : '') + ' and ';
                        //     var lastPrivacy = lastPrivacyConjunction + privacyNames[privacyNames.length - 1];
                        //     return privacyNames.slice(0, -1).join(', ') + lastPrivacy;
                        //   }
                        //   return privacyNames.join('');
                        // }

                        // $scope.getSelectedPrivacyText = function() {
                        //   if ($scope.data.selectedPrivacy.length) {
                        //     return utils.trans('post.selectedPrivacy', {privacy: getSelectedPrivacyNames() });
                        //   } else {
                        //     return utils.trans('addUserViaHiveGroups', true) + ':';
                        //   }
                        // };

                        // function selectPrivatePrivacy() {
                        //   $scope.data.selectedPrivacy = [];
                        //   $scope.data.options.forEach(function(option) {
                        //     if (option.id == 'private') {
                        //       $scope.data.selectedPrivacy.push(option);
                        //     }
                        //   });
                        // }

                        // $scope.filterPrivacySelection = function() {
                        //   if (!$scope.data.selectedPrivacy.length) {
                        //     return;
                        //   }

                        //   if ($scope.data.selectedPrivacy[$scope.data.selectedPrivacy.length-1].name == 'My Hive' || $scope.data.selectedPrivacy[$scope.data.selectedPrivacy.length-1].name == 'Private') {
                        //     $scope.data.selectedPrivacy = $scope.data.selectedPrivacy.slice($scope.data.selectedPrivacy.length-1);
                        //   } else {
                        //     var toRemoveIdx = [];
                        //     for (var i = $scope.data.selectedPrivacy.length - 2; i >= 0; i--) {
                        //       if ($scope.data.selectedPrivacy[i].name == 'My Hive' || $scope.data.selectedPrivacy[i].name == 'Private') {
                        //         toRemoveIdx.push(i);
                        //       }
                        //     }

                        //     toRemoveIdx.forEach(function(id) {
                        //       $scope.data.selectedPrivacy.splice(id, 1);
                        //     });
                        //   }
                        // };
                    }],
                    locals: {
                        item: item
                    }
                    // onComplete: function() {
                    //     var input = $('#share-modal-input')[0];
                    //     input.setSelectionRange(0, input.value.length);
                    // }
                });
            },

            showChatInviteDialog: function(event, item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/chatinvite.html',
                    controller: ['$scope', '$rootScope', '$translate', '$mdDialog', 'utils', 'api', 'users', function($scope, $rootScope, $translate, $mdDialog, utils, api, users) {

                        $scope.data = {
                            hives: [],
                            selectedPrivacy: [],
                            selectedUser: [],
                            options: [],
                            defaultOptions: [],
                            privacyChanged: false,
                            loading: true,
                            caption: 'Please select a hive member or group to invite'
                        };

                        $scope.utils = utils;

                        $scope.nestedSelectLoading = function() {
                          if ($('nested-select').length)
                          {
                            $scope.data.loading = false;
                            return false;
                          }
                          return true;
                        };

                        $scope.privacySelectionDone = function() {
                          console.log('privacySelectionDone: ', $scope.data.privacyChanged);
                        };

                        function loadOptions(options, removePrivate) {
                          removePrivate = removePrivate || false;
                          var removeIdx = -1;
                          options.forEach(function(opt, optIdx) {
                            opt.text = opt.name;
                            opt.user = false;

                            if (removePrivate && opt.type == 'private') {
                              removeIdx = optIdx;
                            }

                            if ('users' in opt) {
                              opt.children = [];
                              opt.collapsed = true;
                              opt.users.forEach(function(user) {
                                var child = {};
                                child.id = user.id;
                                child.text = user.name;
                                child.user = true;
                                child.type = opt.type;
                                opt.children.push(child);
                              });
                            }
                          });

                          if (removeIdx !== -1) {
                            options.splice(removeIdx, 1);
                          }

                          return options;
                        }

                        api.me.privacyOptions().then(function(options) {
                          $scope.data.defaultOptions = loadOptions(options, true);
                          $scope.data.options = angular.copy($scope.data.defaultOptions);
                        });

                        $scope.close = function() {
                            $mdDialog.cancel();
                        };

                        $scope.sendEmail = function() {
                            $scope.data.mailSendInProgress = true;

                            var payload = {};
                            payload['hives'] = $scope.data.selectedPrivacy;
                            payload['users'] = $scope.data.selectedUser;
                            var suffix = window.location.href.split('/');
                            payload['invite_link'] = suffix[suffix.length - 1];
                            console.log(" my payload issss " , payload);
                            api.me.roominvite(payload).then(function(res) {

                              $scope.data.mailSendInProgress = false;
                              utils.showToast(res);

                                // and close the dialog
                                $mdDialog.hide();
                            }, function(err) {
                              //handle failed case
                            });
                        };
                    }]
                });
            },

            showEditFamilyDialog: function(family) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/edit-family-details.html',
                clickOutsideToClose: true,
                controller: ['$scope', '$mdDialog', 'api', 'utils', 'users', function($scope, $mdDialog, api, utils, users) {

                  $scope.data = {
                    title: "editFamilyDetails",
                    okButton: "save",
                    edit_family: {
                      relation: family.relation,
                      description: family.description,
                      name: users.getName(family),
                      email: family.email
                    }
                  };

                  $scope.updateFamilyMember = function(update_family) {
                    api.family.updateFamilyDetails(family.family_id, {relation: $scope.data.edit_family.relation, description: $scope.data.edit_family.description})
                      .then(function success(response) {
                        utils.showToast(utils.trans('familyUpdatedSuccessfully'));
                        $mdDialog.hide(response);
                      }, function error(failure) {
                        utils.showToast(failure);
                        // FIXME: show error on the dialog...
                      });
                  };

                  $scope.close = function() {
                      $mdDialog.cancel();
                  };
                }],
                locals: {
                  family: family
                }
              });
            },

            showLockDialog: function(item) {
                return $mdDialog.show({
                  templateUrl: 'assets/views/modals/lock.html',
                  clickOutsideToClose: true,
                  controller: ['$scope', '$mdDialog', 'lockables', function($scope, $mdDialog, lockables) {
                      $scope.title = 'passwordProtected';
                      $scope.okButton = 'lock';
                      $scope.password = '';

                      $scope.lockSubmit = function() {
                          lockables.lock(item, $scope.password).then(function success(item) {
                              $mdDialog.hide(item);
                          }, function error(failure) {
                              // FIXME: show error on the dialog...
                          });
                      };

                      $scope.close = function() {
                          $mdDialog.cancel();
                      };
                  }]
                });
            },

            showAccountCleanupDialog: function(item) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/account-cleanup.html',
                clickOutsideToClose: true,
                controller: ['$scope', '$mdDialog', 'api', function ($scope, $mdDialog, api) {
                  $scope.options = {};
                    $scope.options.title = 'Account Cleanup';
                    $scope.options.ok = 'Clean up';
                    $scope.options.content = 'Are you sure want to delete ALL USERS permanently?';
                  $scope.options.subcontent = 'Please enter a key';
                  $scope.confirm = function () {
                        api.user.accountCleanup($scope.cleanupKey).then(function success(item) {
                            $mdDialog.hide();
                        }, function error(failure) {
                            // FIXME: show error on the dialog...
                            $scope.error =  'Key is not matched';
                        });
                    };

                    $scope.close = function() {
                        $mdDialog.cancel();
                    };
                }]
              });
          },

            showMakeTemplateStoreDialog: function(item) {
              return $mdDialog.show({
                  templateUrl: 'assets/views/modals/setTemplateStore.html',
                  clickOutsideToClose: true,
                  controller: ['$scope', '$mdDialog', 'utils', 'folders', 'api', function($scope, $mdDialog, utils, folders, api) {

                    $scope.data = {
                      title: 'templateStore.confirm',
                      okButton: 'confirm',
                      confirmTemplateText: utils.trans('templateStore.confirmText', { folder_name: item.name }),
                    };

                    $scope.setTemplateStore = function() {

                      api.folder.setTemplateStore(item).then(function success(response) {
                        // TO DO:
                        // Raise an activiy for template store set
                        // $rootScope.$broadcast('activity.happened', 'created', 'folder', response.data);
                        folders.available.map( function(availabeFolder) {
                          if ( availabeFolder.id == item.id ) {
                            availabeFolder.is_template_folder = 1;
                          }
                          availabeFolder.can_template_store = false;
                        });

                        folders.selected = angular.extend(folders.selected, folders.getById(folders.selected.id));
                        utils.showToast(response, true);
                        $mdDialog.hide(item);
                      }, function error(failure) {
                        utils.showToast(failure, true);
                      });
                    };

                    $scope.cancel = function() {
                        $mdDialog.cancel();
                    };
                }]
              });
            },

            showMoveDialog: function( ) {
              return $mdDialog.show({
                  templateUrl: 'assets/views/modals/move.html',
                  clickOutsideToClose: true,
                  controller: ['$scope', 'api','files', 'folders', 'selectedItems', function($scope, api, files, folders, selectedItems) {
                      $scope.data = {
                        folders         : folders,
                        selectedFolder  : '',
                        folder: null,
                        breadcrumbs: [],
                      };

                      $scope.selectFolder = function(folderId) {
                        $scope.data.selectedFolder = folderId;
                      };

                      $scope.getFolder = function (shareId) {
                        api.folder.getFolder(shareId).then(function (response) {
                            var validFiles = [];

                            response.folder.files.forEach(function(item) {
                                if(item.mime.includes('image')) {
                                    validFiles.push(item);
                                }
                            });

                            response.folder.files = validFiles;
                            $scope.data.folder = response;

                            $scope.selectFolder($scope.data.folder.folder.id);

                            var index = $scope.data.breadcrumbs.findIndex(
                                function (element) {
                                    return (element.shareId == shareId);
                                });

                            if (index >= 0) {
                                $scope.data.breadcrumbs.length = index;
                            }

                            if(shareId == 'root') {
                                $scope.data.breadcrumbs = [{
                                    'name': response.folder.name,
                                    'shareId': response.folder.share_id
                                }];
                            }

                            if ($scope.data.breadcrumbs[$scope.data.breadcrumbs.length - 1]['shareId'] != response.folder.share_id) {
                                $scope.data.breadcrumbs.push({
                                    'name': response.folder.name,
                                    'shareId': response.folder.share_id
                                });
                            }
                        });
                    };

                    $scope.getFolder('root');

                    $scope.itemIsSelected = function(item) {
                      return $scope.data.selectedFolder === item.id ? true : false;
                    };

                    $scope.move = function(folderId) {
                      files.moveToFolder(selectedItems.get(), folderId);
                    };

                    $scope.canMoveTo = function(folder) {
                      if ( selectedItems.first('is_parent_template') && !selectedItems.first('owned_by_current_user') ) {
                        return false;
                      }

                      if ( selectedItems.first('type') === 'file' && selectedItems.first('is_parent_template') && selectedItems.first('owned_by_current_user') ) {
                        return folder.id != selectedItems.first('folder_id') && folder.is_parent_template;
                      }

                      if ( selectedItems.first('type') === 'folder' && selectedItems.first('is_parent_template') && selectedItems.first('owned_by_current_user') ) {
                        return folder.id != selectedItems.first('id') && folder.id != selectedItems.first('folder_id') && folder.is_parent_template;
                      }

                      if (selectedItems.first('type') === 'file') {
                          return (folder.id != selectedItems.first('folder_id') && folder.is_writable ) ;
                          // return (folder.id != selectedItems.first('folder_id') && !folder.is_locked && !folder.is_offer);
                      }

                      if (selectedItems.first('type') === 'folder') {
                        return (folder.id != selectedItems.first('id') && folder.id != selectedItems.first('folder_id') && folder.is_writable);
                        // return (folder.id != selectedItems.first('id') && !folder.is_locked && !folder.is_offer);
                      }
                    };

                    $scope.close = function() {
                      $mdDialog.cancel();
                    };
                  }]
              });
            },

            showMoveItemsDialog: function( data, inst, previousItems ) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/move-override-options.html',
                clickOutsideToClose: false,
                controller: ['$scope', function($scope) {
                    $scope.files = data.notRestored;
                    $scope.selectedItem = 0;

                    $scope.doAction = function() {
                        if(($scope.selectedItem+1) >= $scope.files.length) {
                            inst.restore($scope.files, previousItems);
                            $mdDialog.hide();
                        } else {
                            $scope.selectedItem++;
                        }
                    };

                    $scope.close = function(){
                        $scope.files[$scope.selectedItem].action = "cancel";
                        $scope.doAction();
                    };

                    $scope.replace = function(){
                        $scope.files[$scope.selectedItem].action = "replace";
                        $scope.doAction();
                    };

                    $scope.keepboth = function(){
                        $scope.files[$scope.selectedItem].action = "both";
                        $scope.doAction();
                    };
                }]
              });
            },

            showMoveFileDialog: function( items, notMoved, folderId ) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/move-override-options.html',
                clickOutsideToClose: false,
                controller: ['$scope', 'files', 'utils', function($scope, files, utils) {
                    $scope.notMoved = notMoved;
                    $scope.selected = notMoved[0];

                    $scope.doAction = function(action) {
                        var item = items.find(function(item) {
                            return item.id == $scope.selected.item.id;
                        });
                        item.action = action;

                        var currentIndex = $scope.notMoved.indexOf($scope.selected);
                        if (currentIndex + 1 >= $scope.notMoved.length) {
                            $scope.close();
                            files.moveToFolder(items, folderId);

                        } else {
                            $scope.selected = $scope.notMoved[currentIndex + 1];
                        }
                    };

                    $scope.getMessage = function () {
                        switch($scope.selected.reason) {
                            case 'noNeed':
                                return utils.trans('moveNoNeed', { name: $scope.selected.item.name });

                            case 'locked':
                                return utils.trans('moveLocked', { name: $scope.selected.item.name });

                            case 'duplicateName':
                                return utils.trans('moveDuplicateName', { type: $scope.selected.item.type, name: $scope.selected.item.name });

                            case 'cantReplaceShareBase':
                                return utils.trans('moveCantReplaceShareBase', { type: $scope.selected.item.type, name: $scope.selected.item.name });

                            case 'ancestorOfDestination':
                                return utils.trans('moveAncestorOfDestination', { name: $scope.selected.item.name });

                            case 'noSpaceLeft':
                                return utils.trans('noSpaceLeftForItem', { name: $scope.selected.item.name });

                            // case 'notWritable':
                            default: // No Permission
                                return utils.trans('moveNoPermission', { name: $scope.selected.item.name });
                        }
                    };

                    $scope.shouldHideReplace = function() {
                        return $scope.selected.reason != 'duplicateName';
                    };

                    $scope.shouldHideKeepBoth = function() {
                        return ['duplicateName', 'cantReplaceShareBase'].indexOf($scope.selected.reason) === -1;
                    };

                    $scope.close = function() {
                        $mdDialog.cancel();
                    };
                }]
              });
            },

            showRenameFileDialog: function( name ) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/renamePhoto.html',
                controller: ['$scope', 'files', 'folders', 'utils', 'selectedItems', function($scope, files, folders, utils, selectedItems) {

                    $scope.data = {
                        title    : 'rename',
                        file_name: name
                    };

                    $scope.rename = function() {
                        files.rename(selectedItems.first('id'), $scope.data.file_name).then(function(response) {
                          var folder = folders.getById(response.data.folder_id);

                          if ( ! folder || ! folder.files) {
                            $mdDialog.hide(response.data);
                            utils.showToast('fileRenameSuccess', true);
                          } else {
                              for (var i = 0; i < folder.files.length; i++) {
                                if (folder.files[i].id == response.data.id) {
                                  folder.files[i] = response.data;
                                  $mdDialog.hide(response.data);
                                  utils.showToast('fileRenameSuccess', true);
                                }
                              }
                          }
                      }, function(failure) {
                          utils.showToast(failure.data);
                      });
                    };

                    $scope.close = function() {
                        $mdDialog.cancel();
                    };
                }],
                onComplete: function() {
                    $('#photo-name').focus();
                }
              });
            },

            showUnlockDialog: function(item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/lock.html',
                    clickOutsideToClose: true,
                    controller: ['$scope', '$mdDialog', 'lockables', function($scope, $mdDialog, lockables) {
                        $scope.title = 'unlockProtection';
                        $scope.okButton = 'unlock';
                        $scope.password = '';

                        $scope.lockSubmit = function() {
                            lockables.unlock(item, $scope.password).then(function success(item) {
                                $mdDialog.hide(item);
                            }, function error(failure) {
                                // FIXME: show error on the dialog...
                            });
                        };

                        $scope.close = function() {
                            $mdDialog.cancel();
                        };
                    }]
                });
            },

            showSetFolderImageDialog: function(item) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/select-image.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'api', 'fileTypes', '$rootScope', 'folders', 'utils', function ($scope, api, fileTypes, $rootScope, folders, utils) {
                    $scope.data = {
                        selectedFolder: item,
                        imageId: null,
                        selectedItem: false,
                        folder: null,
                        breadcrumbs: [],
                        loading: false,
                    };

                    $scope.close = function () {
                        $mdDialog.cancel();
                    };

                    $scope.setImage = function() {
                      $scope.data.loading = true;
                      api.folder.setFolderImage($scope.data.selectedFolder.share_id, $scope.data.selectedItem.share_id).then(function() {
                        $scope.data.selectedFolder.folder_image_url = $scope.data.selectedItem.absoluteUrl;
                        $scope.data.selectedFolder.folder_image_id = $scope.data.selectedItem.id;
                        folders.available.map( function(availabeFolder) {
                          if ( availabeFolder.id == $scope.data.selectedFolder.id ) {
                            availabeFolder.folder_image_url = $scope.data.selectedItem.absoluteUrl;
                            availabeFolder.folder_image_id = $scope.data.selectedItem.id;
                          }
                        });
                        folders.selected = angular.extend(folders.selected, folders.getById(folders.selected.id));
                        utils.showToast(utils.trans('folder.folderImageUpdatedSuccessfully'));
                        $mdDialog.hide();
                      }).finally(function() {
                        $scope.data.loading = false;
                      });
                    };


                    $scope.getFolder = function (shareId) {
                        api.folder.getFolder(shareId).then(function (response) {
                            var validFiles = [];

                            response.folder.files.forEach(function (item) {
                                if (item.mime.includes('image')) {
                                    validFiles.push(item);
                                }
                            });

                            response.folder.files = validFiles;
                            $scope.data.folder = response;

                            var index = $scope.data.breadcrumbs.findIndex(
                                function (element) {
                                    return (element.shareId == shareId);
                                });

                            if (index >= 0) {
                                $scope.data.breadcrumbs.length = index;
                            }

                            if (shareId == 'root') {
                                $scope.data.breadcrumbs = [{
                                    'name': response.folder.name,
                                    'shareId': response.folder.share_id
                                }];
                            }

                            if ($scope.data.breadcrumbs[$scope.data.breadcrumbs.length - 1]['shareId'] != response.folder.share_id) {
                                $scope.data.breadcrumbs.push({
                                    'name': response.folder.name,
                                    'shareId': response.folder.share_id
                                });
                            }
                        });
                    };

                    $scope.getFolder('root');

                    $scope.getSearchResults = function (query) {
                        $scope.data.time = new Date().getTime().toString();
                        return api.file.searchMedia($scope.data.searchText).then(function (files) {
                            $scope.data.searchResults = files;
                            return $scope.data.searchResults;
                        });
                    };

                    $scope.getMimeIcon = function (mime) {
                        return fileTypes.getMimeIcon(mime);
                    };

                    $scope.getIsThumbnail = function (item) {
                        return item.flags & 0x02 ? true : false;
                    };

                    $scope.getThumbnail = function (item) {
                        return $rootScope.baseUrl + 'user-file/' + item.share_id + '?thumb=1&t=' + $scope.data.time;
                    };

                    $scope.setUrl = function (item) {
                        $scope.data.selectedItem = item;
                        $scope.data.url = item.type == 'file' ? item.id : '';
                    };
                }]
              });
            },

            showPurchaseTemplateDialog: function(item) {
              var that = this;
              var onCompleteDeferred = $q.defer();
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/purchase-template.html',
                    clickOutsideToClose: true,
                    controller: ['$scope', '$mdDialog', 'files', 'fileTypes', '$rootScope', 'utils', 'users', 'api', 'loadingPromise', function($scope, $mdDialog, files, fileTypes, $rootScope, utils, users, api, loadingPromise) {
                        var publicKey = utils.getSetting('stripePubKey');
                        var stripe = null;
                        var elements = null;
                        var card = null;
                        var intent = null;

                        $scope.data = {
                          loading: false,
                          title: utils.trans('templateStore.templateName', { template_name: item.name }),
                          okButton: !item.is_purchased ? 'tempalateStore.buy' : 'copyTo',
                          purchased: item.is_purchased,
                          imageUrl: '',
                          mimeIcon: undefined,
                          description: item.description,
                          cardDetails: {},
                          invalidCard : !item.is_purchased,
                          months: [1,2,3,4,5,6,7,8,9,10,11,12],
                          years: [],
                          addingNewCard: !users.current.isCardAttached,
                          subscribedMsg: '',
                          errors: ''
                        };

                        var current = new Date().getFullYear();
                        for (var i = current; i < current+15; i++) {
                            $scope.data.years.push(i);
                        }

                        function setImageURL () {
                          // if it's an image or it has a custom thumbnail
                          if( fileTypes.isImage(item.mime) ) { // FIXME: evaluate this method, vs. decomposing flags into the item (has_custom_thumbnail)
                              // $scope.data.imageUrl = $rootScope.baseUrl+'user-file/'+item.share_id+'?w=167&h=131'; // probably something better than this.
                              $scope.data.imageUrl = $rootScope.baseUrl+'user-file/'+item.share_id+'?thumb=1'; // probably something better than this.
                              $scope.data.mimeIcon = undefined;
                          }
                          else if ( item.type=='folder' )
                          {
                              $scope.data.imageUrl = (item.class_type == 'normal') ?
                                  'assets/images/specialfolders/folder.png' :
                                  'assets/images/specialfolders/'+item.class_type+'.png';
                              $scope.data.mimeIcon = undefined;
                          } else {
                              $scope.data.mimeIcon = fileTypes.getMimeIcon(item.mime);
                              $scope.data.imageUrl = undefined;
                          }
                        }

                        $scope.cancel = function() {
                          if ($scope.data.loading){
                            return;
                          }

                          $mdDialog.cancel();
                        };

                      /**
                       * Callback for stripe create card method.
                       *
                       * @param {object} response
                       */
                      function handleStripeResponse(response) {
                        if (response.error) {
                          // Inform the user if there was an error.
                          var errorElement = document.getElementById('card-errors');
                          errorElement.textContent = response.error.message;
                          $scope.data.loading = false;
                        } else {
                          // Send the token to your server.
                          addNewCard(response.token.id);
                        }
                      }

                      /**
                       * Add new credit card to current user.
                       *
                       * @param {object} $scope
                       * @param {string} token
                       */
                      function addNewCard(token) {
                        api.payments.addNewCard(token).then(function(response) {
                          utils.showToast('templateStore.processingMessage', true);
                          users.current.isCardAttached = true;
                          purchaseTemplate();
                        }, function(error) {
                          utils.showToast(error);
                          $scope.data.loading = false;
                        });
                      }

                      function purchaseTemplate() {
                          api.payments.purchaseTemplate(item.id).then(function success(response) {
                          $rootScope.$broadcast('activity.happened', 'purchased', 'template', item);
                          item.is_purchased = true;
                          utils.showToast(response, true);
                          $mdDialog.hide();
                          that.showMovePurchaseDialog(item);
                        }, function error(failure) {
                          utils.showToast(failure, true);
                        }).finally(function() {
                          $scope.data.loading = false;
                        });

                      }

                      $scope.purchase = function() {
                        if ( $scope.data.loading ) {
                          return;
                        }

                        if ( item.is_purchased ) {
                          // $mdDialog.hide(item);
                          that.showMovePurchaseDialog(item);
                        } else {
                          $scope.data.loading = true;
                          $scope.addNewCard();
                        }
                      };

                      $scope.addNewCard = function() {
                        stripe.createToken(card).then(function(result) {
                          handleStripeResponse(result);
                        });
                      };

                      setImageURL();

                      function loadStripeElements() {
                        if ( angular.element('#card-element').length ) {
                          stripe = Stripe(publicKey);
                          elements = stripe.elements();

                          var style = {
                            base: {
                              color: '#32325d',
                              lineHeight: '18px',
                              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                              fontSmoothing: 'antialiased',
                              fontSize: '16px',
                              '::placeholder': {
                                color: '#aab7c4'
                              }
                            },
                            invalid: {
                              color: '#fa755a',
                              iconColor: '#fa755a'
                            }
                          };

                          // Create an instance of the card Element.
                          card = elements.create('card', {hidePostalCode: true, style: style});

                          // Add an instance of the card Element into the `card-element` <div>.
                          card.mount('#card-element');

                          card.addEventListener('change', function(event) {
                            var displayError = document.getElementById('card-errors');
                            if (event.error) {
                              displayError.textContent = event.error.message;
                              $scope.$apply(function() { $scope.data.invalidCard = true; });
                            } else {
                              displayError.textContent = '';
                              $scope.$apply(function() { $scope.data.invalidCard = false; });
                            }
                          });
                        }
                      }

                      loadingPromise().then(function() {
                          if (!$scope.data.purchased) {
                            loadStripeElements();
                          }
                      });
                    }],
                    onComplete: onCompleteDeferred.resolve,
                    locals: {
                      loadingPromise: function () {
                          return onCompleteDeferred.promise;
                      },
                      success: function() {}
                    }
                });
            },

            showRemoveLockDialog: function(item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/lock.html',
                    clickOutsideToClose: true,
                    controller: ['$scope', '$mdDialog', 'lockables', function($scope, $mdDialog, lockables) {
                        $scope.title = 'removePasswordText';
                        $scope.okButton = 'removeLock';
                        $scope.password = '';

                        $scope.lockSubmit = function() {
                            lockables.removeLock(item, $scope.password).then(function success(item) {
                                $mdDialog.hide(item);
                            }, function error(failure) {
                                // FIXME: show error on the dialog...
                            });
                        };

                        $scope.close = function() {
                            $mdDialog.cancel();
                        };
                    }]
                });
            },

            showFacebookImportDialog: function() {
              return $mdDialog.show({
                  templateUrl: 'assets/views/modals/facebook-import.html',
                  clickOutsideToClose: true,
                  controller: ['$scope', '$rootScope', '$mdDialog', 'folders', 'items', function($scope, $rootScope, $mdDialog, folders, items) {
                    $scope.data = {
                      facebookImportStatus : '',
                      facebookImportStatusFormatted : '',
                      buttonsEnabled : false,
                      loginFailed : false,
                    };

                    $rootScope.$on('facebookImportStatusChanged', function(event, status) {       
                      $scope.data.facebookImportStatus = status.includes('error') ? 'error' : status;
                      $scope.data.facebookImportStatusFormatted = status.replace('-', ' ').toUpperCase();	

                      if($scope.data.facebookImportStatus == 'none' || $scope.data.facebookImportStatus == 'error' || $scope.data.facebookImportStatus == 'completed') {
                        $scope.data.buttonsEnabled = true;
                      }
                    });

                    $scope.close = function() {
                      $mdDialog.cancel();
                      $rootScope.$broadcast('facebookImportStatusCompleted');
                    };

                    $scope.startImporting = function() {
                      $scope.data.loginFailed = false;
                      $scope.data.buttonsEnabled = false;
                      $scope.data.facebookImportStatus = 'none';
                      $scope.data.facebookImportStatusFormatted = 'None';
                      $rootScope.$broadcast('startImportingImages');
                    };

                    $rootScope.$on('facebookLoginFailed', function(event) { 
                      $scope.data.loginFailed = true;
                      $scope.data.facebookImportStatusFormatted = 'Import Failed';                      
                      $scope.data.buttonsEnabled = true;
                    });
                    
                  }]
              });
          },

            showCreateOfferDialog: function(event) {
                return dialogs.showUpdateOfferDialog(event, null);
            },

            showUpdateOfferDialog: function(event, offer) {
              return $mdDialog.show({
                targetEvent: event,
                templateUrl: 'assets/views/modals/update-offer.html',
                clickOutsideToClose: true,
                controller: ['$scope', '$mdDialog', 'offers', 'utils', '$rootScope', '$translate', 'newUploadManager', 'api', 'textAngularManager', '$timeout', function($scope, $mdDialog, offers, utils, $rootScope, $translate, newUploadManager, api, textAngularManager, $timeout) {
                  var isCreate = offer ? false : true;
                  $scope.data = {
                    offerModel: offer ? angular.copy(offer) : {},
                    minDate: new Date(),
                    errors: [],
                    labels: {
                      title: isCreate ? $translate.instant('createNewOffer') : $translate.instant('updateOffer'),
                      button: isCreate ? $translate.instant('create') : $translate.instant('update')
                    }
                  };

                  function editorFocus() {
                    $timeout(function() {
                      var editorScope = textAngularManager.retrieveEditor('editor').scope;
                      if (editorScope) {
                          editorScope.displayElements.text.trigger('focus');
                      }
                    }, 5);
                  }

                  editorFocus();

                  $scope.$on('imgeditor.completed', function() {
                    $timeout(function() {
                      var editorScope = textAngularManager.retrieveEditor('editor').scope;
                      if (editorScope) {
                        $scope.data.offerModel.content = editorScope.html;
                      }
                    }, 10);
                  });

                  function updateThumbnail() {
                    if($scope.data.offerModel.hasCustomThumb) {
                      $scope.data.offerThumbnailUrl =  $rootScope.baseUrl+'user-file/offer-'+$scope.data.offerModel.id+'?thumb=1';
                    } else {
                      $scope.data.offerThumbnailUrl = null;
                    }
                  }

                  $scope.removeThumbnail = function() {
                    api.offers.removeThumbnail($scope.data.offerModel.id).then(function() {
                      $scope.data.offerModel.hasCustomThumb = false;
                      $scope.data.offerThumbnailUrl = null;
                    });
                  };

                  function progress(event) {
                    // debugger;
                  }

                  $scope.upload= function(files) {
                    if(files.length) {
                      newUploadManager.upload(files, { url: $rootScope.baseUrl + 'offers/image', progress: progress, fields: { id: offer.id } }).then(function success(data) {
                        if(data.length) {
                          $scope.data.offerThumbnailUrl = data[0].uploadedUrl;
                          $scope.data.offerModel.hasCustomThumb = true;
                        } else {
                          utils.showToast("Something went wrong.");
                        }
                      }, function failure(data) {
                        utils.showToast(data);
                      });
                    }
                  };

                  $scope.updateOffer = function() {
                    var method, messageCode;
                    if(isCreate) {
                      method = offers.create($scope.data.offerModel);
                      messageCode = 'createdOfferSuccessfully';
                    } else {
                      method = offers.updateOffer($scope.data.offerModel, $scope.data.offerModel.id);
                      messageCode = 'updatedOfferSuccessfully';
                    }

                    method.then(function success() {
                        $mdDialog.hide();
                        $scope.data.errors = [];
                        utils.showToast(messageCode, true);
                    }, function error(failure) {
                      setErrors(failure.data);
                    });
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  // first time through.
                  updateThumbnail();

                  function setErrors(errors) {
                    $scope.data.errors = [];
                    if (angular.isString(errors)) {
                      return utils.showToast(errors);
                    }

                    //otherwise append each error
                    for (var field in errors) {
                      $scope.data.errors.push(errors[field][0]);
                    }
                  }
                }]
              });
            },

            showModeIntroDialog: function(selectedMode, modeName, modeContent) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/mode-info-modal.html',
                targetEvent: event,
                clickOutsideToClose: true,
                controller:['$scope', '$mdDialog', 'utils', '$translate', function($scope, $mdDialog, utils, $translate) {
                  $scope.data = {
                    labels: {
                      title: modeName + ' Introduction',
                      content: modeContent,
                      okButton: $translate.instant('Okay')
                    }
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };
                }]
              });
            },

            showPasswordResetDialog: function(event) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/resetPassword.html',
                targetEvent: event,
                clickOutsideToClose: true,
                controller: ['$scope', '$mdDialog', 'offers', 'utils', '$rootScope', '$http', function($scope, $mdDialog, offers, utils, $rootScope, $http) {
                  $scope.data = {
                    email : '',
                    error : '',
                    mailSendInProgress : false
                  };

                  $scope.resetPassword = function() {
                      $scope.data.mailSendInProgress = true;
                      $http.post($rootScope.baseUrl + 'password/email', {email: $scope.data.email}).then(function(response) {
                          utils.showToast(response.data);
                          $scope.data.error = '';
                          $mdDialog.hide();
                          $scope.data.mailSendInProgress = false;
                      }, function(response) {
                          $scope.data.mailSendInProgress = false;
                          $scope.data.error = response.data.email || response.data;
                      });
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                }]
              });
            },

            showSwitchAccountsDialog : function(event) {
              return $mdDialog.show({
                targetEvent: event,
                templateUrl: 'assets/views/modals/switch-accounts.html',
                clickOutsideToClose: true,
                controller: ['$scope', '$upload', 'users', function($scope, $upload, users) {
                  $scope.data =
                  {
                      newUser: '',
                      accounts: users.accounts,
                      users: users
                  };

                  $scope.selectUser = function(user) {
                    $mdDialog.hide(user.id);
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };
                }]
              });
            },

            addMemberDialog: function(event, tagEmail) {
              tagEmail = tagEmail || null;
              return $mdDialog.show({
                targetEvent: event,
                templateUrl: 'assets/views/modals/add-family-member.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'utils', 'api', '$rootScope', function($scope, utils, api, $rootScope) {
                  $scope.data = {
                    emails: [],
                    sentMessage: undefined,
                    mailSendInProgress : false
                  };

                  $scope.utils = utils;
                  if (tagEmail){
                    $scope.data.emails.push({text: tagEmail});
                  }

                  $scope.sendEmail = function() {
                    $scope.data.mailSendInProgress = true;
                    var emails = $scope.data.emails.map(function(e) { return e.text; });
                    api.family.addMember({emails: emails}).then(function(response){
                      utils.showToast(response.message);
                      $scope.data.mailSendInProgress = false;
                      if (tagEmail) {
                        $mdDialog.cancel();
                      } else {
                        $mdDialog.hide(response.emails);
                      }
                    }, function(response) {
                      if (tagEmail) {
                        $rootScope.$broadcast('invite.tag.cancelled',tagEmail);
                      }
                      utils.showToast('genericError', true);
                      $scope.data.mailSendInProgress = false;
                    });
                  };

                  $scope.close = function() {
                    if (tagEmail) {
                      $rootScope.$broadcast('invite.tag.cancelled', tagEmail);
                    }
                    $mdDialog.cancel();
                  };
                }]
              });
            },

            addCustomMemberDialog: function(hiveId) {
              return $mdDialog.show({
                targetEvent: event,
                templateUrl: 'assets/views/modals/add-custom-family-member.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'utils', 'api', 'users', '$mdConstant', function($scope, utils, api, users, $mdConstant) {
                  $scope.data = {
                    emails: [],
                    sentMessage: undefined,
                    mailSendInProgress : false,
                    members: [],
                    filtered: [],
                    keys: [$mdConstant.KEY_CODE.COMMA],
                    loaded: false,
                    searchText: ''
                  };

                  api.family.getFamilyForCustom(hiveId).then(function(members) {
                    $scope.data.loaded = true;
                    $scope.data.members = members.map(function(member) {
                      member.name = users.getName(member);
                      member.image = users.getAvatar(member);
                      member._lowername = member.name.toLowerCase();
                      return member;
                    });
                    $scope.querySearch();
                  }, function() {
                    $scope.data.loaded = true;
                  });

                  $scope.addToCustom = function() {
                    $scope.data.mailSendInProgress = true;
                    var memberIds = $scope.data.emails.map(function(e) { return e.id; });
                    api.family.addMembersToCustom(memberIds, hiveId).then(function(hiveMembers) {
                      utils.showToast(utils.trans('hive.membersAddedCustom', true));
                      $scope.data.mailSendInProgress = false;
                      $mdDialog.hide(hiveMembers);
                    }, function(response) {
                      utils.showToast(response, true);
                      $scope.data.mailSendInProgress = false;
                      $mdDialog.hide();
                    });
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  $scope.printSelectedMembers = function() {
                    var memberNames = [];
                    $scope.data.emails.forEach(function(email) {
                      $scope.data.members.forEach(function(member) {
                        if (member.id == email) {
                          memberNames.push(member.name);
                        }
                      });
                    });

                    var numberOfMembers = memberNames.length;

                    if (numberOfMembers > 1) {
                      var needsOxfordComma = numberOfMembers > 2;
                      var lastMemberConjunction = (needsOxfordComma ? ',' : '') + ' and ';
                      var lastMember = lastMemberConjunction +
                          memberNames[memberNames.length - 1];
                      return memberNames.slice(0, -1).join(', ') + lastMember;
                    }

                    return memberNames.join('');
                  };

                  $scope.querySearch = function() {
                    if ($scope.data.searchText) {
                      $scope.data.filtered = $scope.data.members.filter(createFilterFor());
                    } else {
                      $scope.data.filtered = $scope.data.members;
                    }
                  };

                  function createFilterFor() {
                    var lowercaseQuery = $scope.data.searchText.toLowerCase();

                    return function filterFn(member) {
                      return (member._lowername.indexOf(lowercaseQuery) !== -1);
                    };
                  }

                  $scope.toggle = function (item) {
                    var idx = $scope.data.emails.indexOf(item);
                    if (idx > -1) {
                      $scope.data.emails.splice(idx, 1);
                    }
                    else {
                      $scope.data.emails.push(item);
                    }
                  };

                  $scope.exists = function (item) {
                    return $scope.data.emails.indexOf(item) > -1;
                  };

                  $scope.clearSearchText = function() {
                    $scope.data.searchText = "";
                    $scope.querySearch();
                  };
                }]
              });
            },

            showAddHiveGroupDialog: function(hive) {
              return $mdDialog.show({
                targetEvent: event,
                templateUrl: 'assets/views/modals/add-hive-group.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'utils', 'api', '$translate', function($scope, utils, api, $translate) {
                  var isCreate = hive ? false : true;
                  $scope.data = {
                    name: hive && hive.name ? hive.name : '',
                    hive: hive ? angular.copy(hive) : {},
                    addInProgress : false,
                    labels: {
                      title: isCreate ? $translate.instant('hive.addHiveGroup') : $translate.instant('hive.editHiveGroup'),
                      button: isCreate ? $translate.instant('hive.addHiveButtonText') : $translate.instant('hive.editHiveButtonText'),
                      saving: isCreate ? $translate.instant('hive.adding') : $translate.instant('hive.editing')
                    }
                  };
                  $scope.utils = utils;


                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  $scope.addHiveGroup = function() {
                    var hive, method, messageCode;
                    $scope.data.addInProgress = true;
                    if (isCreate) {
                      hive = new api.resources.hive();
                      hive.name = $scope.data.hive.name;
                      method = hive.$save();
                      messageCode = 'hive.addHiveSuccess';
                    } else {
                      hive = $scope.data.hive;
                      method = hive.$update();
                      messageCode = 'hive.editHiveSuccess';
                    }

                    method.then(function(data) {
                      $scope.data.addInProgress = false;
                      utils.showToast(utils.trans(messageCode, true));
                      $mdDialog.hide(data);
                    }, function(res) {
                      $scope.data.addInProgress = false;
                      utils.showToast(res.data);
                    });
                  };
                }]
              });
            },

            showDeleteHiveGroupDialog: function(hive) {
              return $mdDialog.show({
                targetEvent: event,
                templateUrl: 'assets/views/modals/delete-hive-group.html',
                clickOutsideToClose: true,
                controller: ['$scope', 'utils', 'api', '$translate', function($scope, utils, api, $translate) {
                  $scope.data = {
                    name: '',
                    hive: hive ? angular.copy(hive) : {},
                    deleteInProgress : false,
                    labels: {
                      title: $translate.instant('hive.deleteHiveGroup'),
                      button: $translate.instant('hive.deleteGroup'),
                      content: utils.trans('hive.deleteGroupContent', {group_name: hive.name }),
                      saving: $translate.instant('hive.deleting')
                    }
                  };
                  $scope.utils = utils;


                  $scope.close = function() {
                    $mdDialog.cancel();
                  };

                  $scope.deleteHiveGroup = function() {
                    $scope.data.hive.$delete().then(function() {
                      utils.showToast(utils.trans('hive.deleteSuccessful'));
                      $mdDialog.hide();
                    }, function(res) {
                      utils.showToast(res);
                    });
                  };
                }]
              });
            },

            showPreviewDialog: function(event, items, images) {  
                if(items && items.length == 1 && images && images.length > 1) {
                  var index = images.indexOf(items[0]);
                  if(index != 0) {
                    var part1 = images.toSpliced(0, index);
                    var part2 = images.toSpliced(index, images.length);
                    items = part1.concat(part2);
                  } else {
                    items = images;
                  }
                }

                function formatItemsArray(itemsToFilter) {
                    var result = null;

                    return itemsToFilter.filter(function(item) {
                        if (item.type === 'file') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    });
                }

                if(formatItemsArray(items).length==0) {
                    alert("Nothing to show (return empty promise?)");
                }

                return $mdDialog.show({
                  controller: ['$scope', '$mdDialog', 'selectedItems', '$rootScope', 'items', 'fileTypes', 'dialogManager', 'utils', '$translate', function($scope, $mdDialog, selectedItems, $rootScope, items, fileTypes, dialogManager, utils, $translate) {
                      $scope.data = {
                          items: formatItemsArray(items),
                          index: 0,
                          // previewType: 'none',
                          isViewOnly: false,
                          iconType: undefined
                      };

                      function update() {
                          $scope.data.iconType = fileTypes.getMimeIcon($scope.data.items[$scope.data.index].mime);
                          $scope.data.isViewOnly = ($scope.data.items[$scope.data.index].is_shared_base && !$scope.data.items[$scope.data.index].is_downloadable) || $scope.data.items[$scope.data.index].is_media_folder;
                      }
                      $scope.nextItem = function() {
                          if($scope.data.index+1<$scope.data.items.length) {
                              $scope.data.index++;
                          } else {
                            $scope.data.index = 0;
                          }
                          update();
                      };

                      $scope.prevItem = function() {
                          if($scope.data.index-1 >= 0) {
                              $scope.data.index--;
                          } else {
                            $scope.data.index = $scope.data.items.length-1;
                          }
                          update();
                      };

                      $scope.close = function() {
                          $mdDialog.cancel();
                      };

                      $scope.share = function() {
                          $mdDialog.hide({ postVerb: 'share', item: $scope.data.items[$scope.data.index] });
                      };
                      /*
                      $scope.copyLink = function() {
                          var item = $scope.data.items[$scope.data.index];
                          var link = $rootScope.baseUrl+(! utils.getSetting('enablePushState') ? '#/' : '')+'view/'+item.type+'/'+item.share_id + '/' + item.name;

                          // dom manip.
                          var node = document.createElement('input'); node.value = link;
                          document.body.appendChild(node);
                          node.select();
                          var copied = document.execCommand('copy');
                          document.body.removeChild(node);

                          if(!copied) {
                              // showToast.
                              utils.showToast($translate.instant('copyNotSupported'));
                          } else {
                              // show copied toast.
                              utils.showToast($translate.instant('copiedLink'));
                          }
                      };
                      */
                      $scope.download = function() {
                          selectedItems.download($scope.data.items[$scope.data.index]);
                      };

                      $scope.share = function($event) {
                          // close first?
                          dialogManager.showShareDialog($event, $scope.data.items[$scope.data.index]);
                      };

                      $scope.print= function() {
                        selectedItems.print();
                      };

                      // initial load
                      update();

                  }],
                  templateUrl: 'assets/views/modals/preview.html',
                  parent: angular.element(document.body),
                  targetEvent: event,
                  clickOutsideToClose: true,
                  escapeToClose: false, // SO THAT THE COMMENT BOX CLOSES ON ESCAPE
                  locals: {
                      items: items
                  },
                  fullscreen: true // Only for -xs, -sm breakpoints.
                });
            },


            confirmDeleteFamilyMember: function(name, type) {
              return $mdDialog.show({
                  templateUrl: 'assets/views/modals/delete-member.html',
                  clickOutsideToClose: true,
                  controller: ['$scope', 'utils', function($scope, utils) {
                      $scope.data = {
                        title: 'family.deleteMember',
                        content: type == 'default' ? utils.trans('family.deleteMemberConfirm', {name: name }) : utils.trans('hive.deleteFromGroupOnly', {name: name }),
                        additionalText: type == 'default' ? utils.trans('hive.deleteFromDefaultGroupAdditionalText') : utils.trans('hive.deleteFromGroupAdditionalText'),
                        ok: 'delete',
                        shareItemsText: 'family.shareItemsText',
                        sharedItems: false,
                        type: type
                      };

                      $scope.confirm = function() {
                        $mdDialog.hide($scope.data.sharedItems);
                      };

                      $scope.close = function() {
                          $mdDialog.cancel();
                      };
                  }]
              });
            },

            inviteAsMember: function(email) {
              return $mdDialog.show({
                  templateUrl: 'assets/views/modals/invite-as-member.html',
                  clickOutsideToClose: true,
                  multiple: true,
                  controller: ['$scope', 'utils', function($scope, utils) {
                      $scope.data = {
                        title: 'family.inviteAsMember',
                        content: utils.trans('family.inviteAsMemberText', {email: email }),
                        ok: 'family.invite'
                      };

                      $scope.confirm = function() {
                        dialogs.addMemberDialog(null, email).then(function(res) {
                          $mdDialog.hide(res);
                        }, function() {
                        });
                      };

                      $scope.close = function() {
                          $mdDialog.hide(false);
                      };
                  }]
              });
            },

            showSharedInfo: function(event, item) {
                return $mdDialog.show({
                    templateUrl: 'assets/views/modals/share-info.html',
                    clickOutsideToClose: true,
                    controller: ['$scope', '$mdDialog', 'item', 'api', 'folders', '$rootScope', 'utils', function($scope, $mdDialog, item, api, folders, $rootScope, utils) {
                        $scope.data = {
                            item: item
                        };
                        // $scope.data = {
                        //     title: item.title,
                        //     image_url: item.image_url,
                        //     description: item.description
                        // };
                        $scope.close = function() {
                            $mdDialog.cancel();
                        };

                        // Remove one that I've shared out
                        $scope.unshare = function(event, shareInfo) {
                            var confirm = $mdDialog.confirm()
                                .title('Stop Sharing?')
                                .textContent('Would you like to stop sharing this file?')
                                .targetEvent(event)
                                .ok('Stop Sharing')
                                .cancel('Cancel');

                            $mdDialog.show(confirm).then(function() {
                                api.share.unshareById(shareInfo.id).then(function() {
                                    folders.selectByShareId(item.share_id, false, true).then(function(response) {
                                      var updated_folder = folders.getByShareId(item.share_id);
                                      var new_shared = updated_folder.is_being_shared || updated_folder.is_shared_base;
                                      if ( !new_shared ) {
                                        $rootScope.$broadcast('sharing.stopped', updated_folder);
                                      }
                                    });
                                    folders.refreshFiles();
                                    utils.showToast(utils.trans('sharingWithStopped', {name: $scope.data.item.name }));
                                });
                            }, function() {
                            });
                        };

                        // Remove one that I've accetpted a share of.
                        $scope.unlink = function(event, shareInfo) {
                            // show confirm...
                            var confirm = $mdDialog.confirm()
                                .title('Stop Sharing?')
                                .textContent('Would you like to stop sharing this file?  You will not be able to see it again unless the original owner re-shares it with you.')
                                .targetEvent(event)
                                .ok('Stop Sharing')
                                .cancel('Cancel');

                            $mdDialog.show(confirm).then(function() {
                                api.share.unlink([ $scope.data.item ]).then(function() {
                                    folders.refreshFiles();
                                });
                            }, function() {
                            });
                        };

                        $scope.data.loading = true;
                        api.share.info(item.type, item.share_id).then(function(result) {
                            $scope.data.info = result;
                            $scope.data.error = undefined;
                        }, function failure(message) {
                            $scope.data.error = message;
                        }).finally(function() {
                            $scope.data.loading = false;
                        });
                    }],
                    locals: {
                        item: item
                    }
                });
            },

            showFirstRunDialog: function(item) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/first-run.html',
                clickOutsideToClose: true,
                controller: ['$scope', '$mdDialog', 'item', function($scope, $mdDialog, item) {
                  $scope.data = {
                    title: item.title,
                    image_url: item.image_url,
                    description: item.description
                  };
                  $scope.close = function() {
                    $mdDialog.cancel();
                  };
                }],
                locals: {
                  item: item
                }
              });
            },

            showChangeThumbnailDialog: function(item) {
                return $mdDialog.show({
                  templateUrl: 'assets/views/modals/change-thumbnail.html',
                  clickOutsideToClose: true,
                  controller: ['$scope', '$mdDialog', 'item', 'newUploadManager', 'utils', 'fileTypes', 'api', function($scope, $mdDialog, item, newUploadManager, utils, fileTypes, api) {
                    $scope.data = {
                        name: item.name,
                        hasCustomThumb: false,
                        thumbnailUrl: "",
                        changed: false
                    };

                    function updateThumbnail(theItem) {
                        if(fileTypes.isImage(theItem.mime) || (theItem.flags & 0x02)) {
                            $scope.data.hasCustomThumb = true;
                            $scope.data.thumbnailUrl = '/user-file/'+theItem.share_id+'?thumb=1'; // probably something better than this.
                        } else {
                            $scope.data.hasCustomThumb = false;
                            $scope.data.thumbnailUrl = "";
                        }
                    }

                    $scope.close = function() {
                        if($scope.data.changed) {
                            $mdDialog.hide({ changed: true, item: item });
                        } else {
                            $mdDialog.cancel();
                        }
                    };

                    function progress(event) {
                      // debugger;
                    }

                    $scope.removeThumbnail = function() {
                        api.file.deleteThumbnail(item.share_id).then(function success(item) {
                            updateThumbnail(item);
                            $scope.$emit('activity.happened', 'thumbnail', 'file', item);
                            $scope.data.changed = true;
                        }, function failure(msg) {
                            debugger;
                        });
                    };

                    $scope.upload = function(files) {
                      if(files.length) {
                        newUploadManager.upload(files, { url: '/files/thumbnail', progress: progress, fields: { id: item.share_id } }).then(function success(data) {
                          if(data.length) {
                            $scope.data.thumbnailUrl = data[0].uploadedUrl;
                            $scope.data.hasCustomThumb = true;
                            $scope.data.changed = true;

                            // ideally server would send this back to us..
                            item.flags |= 0x02;
                            $scope.$emit('activity.happened', 'thumbnail', 'file', item);
                          } else {
                            utils.showToast("Something went wrong.");
                          }
                        }, function failure(data) {
                          utils.showToast(data);
                        });
                      }
                    };

                    // initial load stuff.
                    updateThumbnail(item);
                  }],
                  locals: {
                    item: item
                  }
                });
            },

            showEditFirstRunDialog: function(event, item) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/edit-first-run.html',
                clickOutsideToClose: false,
                locals: {
                  item: item
                },
                targetEvent: event,
                controller: ['$scope', 'item', '$mdDialog', 'newUploadManager', 'utils', 'textAngularManager', '$timeout', function($scope, item, $mdDialog, newUploadManager, utils, textAngularManager, $timeout) {
                  $scope.data = {
                    title: item ? item.title : '',
                    description: item ? item.description : '',
                    short_name: item ? item.short_name : '',
                    bit_value: item ? item.bit_value : '',
                    image_url: item ? item.image_url : false
                  };

                  function progress(event) {
                    // debugger;
                  }


                  function editorFocus() {
                    $timeout(function() {
                      var editorScope = textAngularManager.retrieveEditor('editor').scope;
                      if (editorScope) {
                          editorScope.displayElements.text.trigger('focus');
                      }
                    }, 5);
                  }

                  editorFocus();

                  $scope.$on('imgeditor.completed', function() {
                    $timeout(function() {
                      var editorScope = textAngularManager.retrieveEditor('editor').scope;
                      if (editorScope) {
                        $scope.data.description = editorScope.html;
                      }
                    }, 10);
                  });

                  $scope.upload = function(files) {
                    if(files.length) {
                      newUploadManager.upload(files, { url: '/faqs/image', progress: progress, fields: {} }).then(function success(data) {
                        if(data.length) {
                          $scope.data.image_url = data[0].uploadedUrl;
                        } else {
                          utils.showToast("Something went wrong.");
                        }
                      }, function failure(data) {
                        utils.showToast(data);
                      });
                    }
                  };

                  $scope.save = function() {
                    var result = {
                      item: item
                    };

                    angular.forEach($scope.data, function(value, key) {
                      result[key] = value;
                    });

                    $mdDialog.hide(result);
                  };

                  $scope.close = function() {
                    $mdDialog.cancel();
                  };
                }]
              });
            },
            showEmailLoggerDetailDialog: function(event, item) {
              return $mdDialog.show({
                templateUrl: 'assets/views/modals/show-email-logger-detail-dialog.html',
                clickOutsideToClose: false,
                locals: {
                  item: item
                },
                targetEvent: event,
                controller: ['$scope', 'item', '$mdDialog', 'utils', function($scope, item, $mdDialog, utils) {
                  $scope.data = {
                    title: item ? 'Email Logger for '+item.to : '',
                    from: item ? item.from : '',
                    to: item ? item.to : '',
                    subject: item ? item.subject : '',
                    source: item ? item.source : '',
                    createDate: item ? item.create_date : '',
                    sentDate: item ? item.sent_date : '',
                    body: item ? item.body : '',
                  };
                  $scope.close = function() {
                    $mdDialog.cancel();
                  };
                }]
              });
            }
          };
          return dialogs;
        }]);
}());