(function () {
	'use strict';

	angular.module('pixie.dashboard')
		.directive('fileDetails', ['$http', '$translate', 'users', 'folders', '$rootScope', 'utils', 'api', '$q', 'fileTypes', 'dialogManager', function ($http, $translate, users, folders, $rootScope, utils, api, $q, fileTypes, dialogManager) {
			return {
				restrict: 'E',
				templateUrl: 'assets/views/directives/file-details.html',
				scope: {
					file: "=",
				},
				link: function ($scope, el) {
					var type = utils.stateIs('dashboard.timeline') || utils.stateIs('dashboard.buzz.timeline') || utils.stateIs('dashboard.hives.details.member') || utils.stateIs('dashboard.hives.details.member.post') ? 'post' : 'file';
					$scope.users = users;
					$scope.utils = utils;

					$scope.editingDescription = false;
					$scope.tagging = false;
					$scope.newTags = [];
					$scope.newDescription = "";
					$scope.newComment = "";


					$scope.progress = {
						description: false,
						comments: false,
						postComment: false,
						like: false,
						tags: false
					};

					$scope.data = {
						commentsTotal: 0,
						likesTotal: 0,
						likedByUser: false,
						fileOwner: {},
						isImage: fileTypes.isImage($scope.file.mime),
						tags: []
					};

					$scope.details = {};

					$scope.comments = [];

					$scope.load = function () {
						api.item.likes($scope.file.id, type).then(function (data) {
							$scope.data.likesTotal = data.total;
							$scope.data.likedByUser = data.user_like ? true : false;
						});

						$http.get($rootScope.baseUrl + 'comments?parent=0&item_id=' + $scope.file.id + '&type=' + type).then(function (response) {
							$scope.comments = response.data;
							$scope.data.commentsTotal += $scope.comments.length;
						});

						if ($scope.file.user_id != users.current.id) {
							$http.get($rootScope.baseUrl + 'users/' + $scope.file.user_id + '?type=' + type).then(function (response) {
								$scope.data.fileOwner = response.data;
							});
						} else {
							$scope.data.fileOwner = users.current;
						}

						api.file.getTags($scope.file.id).then(function (response) {
							$scope.data.tags = tagsToAutoComplete(response);
						});
					};

					$scope.editDescription = function () {
						$scope.newDescription = $scope.file.description;
						$scope.editingDescription = true;
					};

					// $scope.removeTag = function(tag) {
					// 	api.file.remove();
					// };

					$scope.tagPeople = function () {
						$scope.newTags = $scope.data.tags;
						$scope.tagging = true;
					};

					$scope.updateTags = function () {
						$scope.progress.tags = true;

						var tagIds = [];
						var tagEmails = [];

						angular.forEach($scope.newTags, function (tag, key) {
							if ('id' in tag && tag.id) {
								tagIds.push(tag.id);
							} else {
								tagEmails.push(tag.text);
							}
						});

						api.file.updateTags($scope.file.id, tagIds, tagEmails).then(function (response) {
							utils.showToast(response);
							$scope.data.tags = $scope.newTags;
							$scope.tagging = false;
							$scope.progress.tags = false;
							$scope.newTags = [];
						}, function (error) {
							utils.showToast(error.data);
						});
					};

					function tagsToAutoComplete(tags) {
						var members = [];

						angular.forEach(tags, function (value, key) {
							var member = { email: value.email, avatar_url: value.avatar_url };

							if ('id' in value) {
								member['id'] = value.id;
								member['text'] = users.getName(value);
							} else {
								member['text'] = value.email;
							}
							members.push(member);
						});
						return members;
					}

					$scope.loadTagList = function (query) {
						var deferred = $q.defer();

						api.file.getTagSuggestions($scope.file.id, query).then(function (tags) {
							deferred.resolve(tagsToAutoComplete(tags));
						}, function () {
							deferred.resolve(false);
						});
						return deferred.promise;
					};

					$scope.cancelTagging = function () {
						$scope.newTags = [];
						$scope.tagging = false;
						$scope.progress.tags = false;
					};

					$scope.cancelEdit = function () {
						$scope.newDescription = "";
						$scope.editingDescription = false;
					};

					$scope.toggleLike = function () {
						api.item.toggleLike($scope.file.id, type).then(function (data) {
							if (!data.likedByUser) {
								$rootScope.$broadcast('activity.happened', 'unliked', 'file description', $scope.file);
							} else {
								$rootScope.$broadcast('activity.happened', 'liked', 'file description', $scope.file);
							}
							$scope.data.likedByUser = data.likedByUser;
							$scope.data.likesTotal = data.likesTotal;
						}, function failure(data) {
							utils.showToast(data);
						});
					};

					$scope.deleteComment = function (comment) {
						$scope.comments.splice($scope.comments.indexOf(comment), 1);
						$scope.data.commentsTotal -= 1;
					};

					$scope.updateDescription = function () {
						if ($scope.progress.description) { return; }

						$scope.progress.description = true;
						var newDescription = $scope.newDescription.replace(/(\s|\t|(<br *\/?>))+$/, "");

						api.file.updateDescription($scope.file.id, newDescription).then(function (data) {
							$rootScope.$broadcast('activity.happened', 'edited', 'file', data);
							utils.showToast('descriptionEdited', true);

							$scope.editingDescription = false;
						}, function (response) {
							utils.showToast(response);

						}).finally(function () {
							$scope.progress.description = false;
						});
					};

					$scope.addComment = function (comment) {
						$scope.comments.push(comment);
						$scope.data.commentsTotal += 1;
					};

					$scope.updateTotalWithReply = function (reply_count) {
						$scope.data.commentsTotal += reply_count;
					};

					$scope.isHiveMember = function (email) {
						if (angular.isObject(email)) {
							if (!email.email) {
								dialogManager.inviteAsMember(email.text).then(function (res) {
									if (res == false) {
										removeTagPersonFromList(email.text);
									}
								}, function () {
								});
							}
						}
						return utils.validateEmail(email);
					};

					function removeTagPersonFromList(email) {
						$scope.newTags = $scope.newTags.filter(function (tag) {
							return (tag.text != email);
						});
					}

					$scope.$on('invite.tag.cancelled', function (e, email) {
						if ($scope.newTags.length) {
							removeTagPersonFromList(email);
						}
					});

					$scope.$watch('file', $scope.load);
				}
			};
		}])

		.directive('comment', ['users', 'utils', '$http', '$rootScope', '$mdDialog', function (users, utils, $http, $rootScope, $mdDialog) {

			return {
				restrict: 'E',
				templateUrl: 'assets/views/directives/comment.html',
				scope: {
					comment: "=",
					file: "=",
					type: "@?",
					onDelete: "&",
					onUpdate: "=?",
					itemId: "=",
				},
				link: function ($scope, element, attribute) {
					var originatorEv;
					var type = utils.stateIs('dashboard.timeline') || utils.stateIs('dashboard.buzz.timeline') || utils.stateIs('dashboard.hives.details.member') || utils.stateIs('dashboard.hives.details.member.post')  ? 'post' : 'file';
					$scope.users = users;
					$scope.newComment = "";
					$scope.replies = [];
					$scope.data = {
						replying: false
					};

					$scope.onUpdate = typeof $scope.onUpdate == "function" ? $scope.onUpdate : function () { };

					$scope.openMenu = function ($mdMenu, ev) {
						originatorEv = ev;
						$mdMenu.open(ev);
					};

					$scope.loadReplies = function () {
						if (!$scope.type || $scope.type == 'comment') {
							$http.get($rootScope.baseUrl + 'comments?parent_id=' + $scope.comment.id + '&item_id=' + $scope.itemId + '&type=' + type).then(function (response) {
								$scope.replies = response.data;
								$scope.onUpdate($scope.replies.length);
							});
						}
					};

					$scope.editComment = function () {
						$scope.comment.editing = true;
						$scope.newComment = $scope.comment.comment;
					};

					$scope.cancelCommentEdit = function () {
						$scope.comment.editing = false;
					};

					$scope.deleteComment = function () {
						$http.delete($rootScope.baseUrl + 'comments/' + type + '/' + $scope.comment.id).then(function (response) {
							$scope.onDelete();
							$scope.onUpdate(-$scope.replies.length);
							utils.showToast(response.data);
						});
					};

					$scope.updateCommentEditing = function () {
						var newComment = $scope.newComment.replace(/(\s|\t|(<br *\/?>))+$/, "");
						$http.put($rootScope.baseUrl + 'comments/' + $scope.comment.id, { comment: newComment, type: type }).then(function (response) {
							$scope.comment.comment = newComment;
							$scope.comment.editing = false;
							$scope.newComment = '';
						});
					};

					$scope.showReplyBox = function () {
						if ($scope.type == 'reply') {
							return $scope.$parent.showReplyBox();
						}
						$scope.data.replying = true;
					};

					$scope.addReply = function (reply) {
						$scope.replies.push(reply);
						$scope.data.replying = false;
						$scope.onUpdate(1);
					};

					$scope.hideReplyBox = function () {
						$scope.data.replying = false;
					};

					$scope.deleteReply = function (reply) {
						$scope.replies.splice($scope.replies.indexOf(reply), 1);
						$scope.onUpdate(-1);
					};

					$scope.isDeletable = function () {
						return $scope.users.current.id == $scope.file.user_id || $scope.isEditable();
					};

					$scope.isEditable = function () {
						return $scope.users.current.id == $scope.comment.user_id;
					};

					$scope.loadReplies();
				}
			};

		}])


		.directive('commentBox', ['users', 'utils', '$http', '$rootScope', function (users, utils, $http, $rootScope) {

			return {
				restrict: 'E',
				templateUrl: 'assets/views/directives/comment-box.html',
				scope: {
					comment: "=?",
					file: "=",
					onPost: "=",
					onEscape: "=",
					itemId: "=",
				},
				link: function ($scope, element, attribute) {
					angular.element(element).addClass("comment-box");
					var type = utils.stateIs('dashboard.timeline') || utils.stateIs('dashboard.buzz.timeline') || utils.stateIs('dashboard.hives.details.member') || utils.stateIs('dashboard.hives.details.member.post') ? 'post' : 'file';
					$scope.users = users;
					$scope.data = {
						comment: '',
						posting: false
					};

					$scope.postComment = function () {
						
						if ($scope.data.posting || !$scope.data.comment) { return; }
						$scope.data.posting = true;
						var fileInfo = {
							'share_id': $scope.file.share_id,
							'user_id': $scope.file.user_id,
							'folder_id': $scope.file.folder_id
						};
												
						var parentId = $scope.comment ? $scope.comment.id : 0;
						$http.post($rootScope.baseUrl + 'comments', { comment: $scope.data.comment, parent: parentId, item_id: $scope.itemId, type: type, file_info: fileInfo }).then(function (response) {
							if (typeof $scope.onPost == 'function') {
								$scope.onPost(response.data);
							}
							utils.showToast('commentPosted', true);
							$rootScope.$broadcast('activity.happened', 'commented on', type, $scope.file);
							$scope.data.comment = "";
							$scope.data.posting = false;
							// TODO: SCROLL TO THE LATEST COMMENT
						});
					};

					element.bind("keydown keypress", function (event) {
						if ($scope.data.posting) {
							return event.preventDefault();
						}

						if (event.which === 27 && !event.shiftKey) {
							this.blur();
							event.preventDefault();
							if (typeof $scope.onEscape == 'function') {
								$scope.data.comment = '';
								$scope.$apply(function () {
									$scope.onEscape();
								});
							}
						}
					});
				}
			};
		}]);
})();