(function () {
'use strict';

angular.module('app')

.controller('SocialLoginController', ['$rootScope', '$scope', '$http', '$state', '$mdDialog', '$mdToast', '$translate', 'users', function($rootScope, $scope, $http, $state, $mdDialog, $mdToast, $translate, users) {

    $scope.credentials = {};

    $scope.loginWith = function(service) {
        window.location.href = $scope.baseUrl + 'auth/' + service;
    };

    $scope.socialLoginCallbackError = function() {
        $mdToast.show($mdToast.simple({ position: 'bottom right' }).content($translate.instant('genericSocialError')));
    };

    $scope.createAndLoginUser = function() {
        $http.post($rootScope.baseUrl+'auth/social/request-email-callback', { email: $scope.credentials.email }).then(function(response) {
            $scope.goToDashboard(response.data);
        }, function(response) {

            //User with email already exists and has password
            //so we need to request it to connect the accounts
            if (response.data.code === 1) {
                //$scope.closeModal();
                $scope.requestUserPassword();
            }
        });
    };

    $scope.connectAccounts = function() {
        $http.post($rootScope.baseUrl+'auth/social/connect-accounts', {password: $scope.credentials.password}).then(function(response) {
            $scope.goToDashboard(response.data);
        }, function(response) {
            $scope.errorMessage = response.data;
        });
    };

    $scope.requestUserPassword = function($event) {
        $mdDialog.show({
            templateUrl: 'assets/views/modals/request-password-modal.html',
            targetEvent: $event,
            controller: 'SocialLoginController'
        });
    };

    $scope.requestUserEmail = function($event) {
        $mdDialog.show({
            templateUrl: 'assets/views/modals/request-email-modal.html',
            targetEvent: $event,
            controller: 'SocialLoginController'
        });
    };

    $scope.closeModal = function() {
        $mdDialog.hide();
    };

    $scope.goToDashboard = function(user) {
        users.current = user;
        $rootScope.tempUser = undefined;
        $scope.credentials = {};
        $scope.errorMessage = undefined;

        $scope.closeModal();
        $state.go('dashboard.folders');
    };
}]);
}());
