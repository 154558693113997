(function () {

'use strict';

angular.module('app')

.controller('LoginController', ['$rootScope', '$scope', '$http', '$state', '$mdDialog', 'users', 'utils', '$stateParams', 'dialogManager', function($rootScope, $scope, $http, $state, $mdDialog, users, utils, $stateParams, dialogManager) {

    $scope.registrationEnabled = true;

    $scope.credentials = {
        remember: true
    };

    function goToDashboard() {
        if($stateParams.nextState !== undefined && $stateParams.nextState) {
            $state.go($stateParams.nextState, $stateParams.additionalParams);
        } else {
            if ($stateParams.family_invite_link) {
                $state.go('dashboard.hives', { family_invite_link: $stateParams.family_invite_link });
            } else if ($stateParams.share_link) {
                $state.go('dashboard.folders', { share_link: $stateParams.share_link });
            } else if ($stateParams.room_link) {
                $state.go('dashboard.rooms', { roomId: $stateParams.room_link });
            } else {
                $state.go('dashboard.story', { share_link: $stateParams.share_link, family_invite_link: $stateParams.family_invite_link });
            }
        }
    }

    // check to see if the user is already logged in; if they are, redirect them (this also uses the remember token)
    users.loggedIn().then(function success(data) {
        goToDashboard();
    }, function failure(data) {
        // nothing.
    });

    if($stateParams.share_link) {
        $http.get($rootScope.baseUrl + 'shareable/check-link?link='+$stateParams.share_link).then(function(response) {
            $scope.share_owner = response.data.ownerName;
        }, function(error){
            // clear off the share_link
            $state.go('login', { share_link: null  });
        });
    }

    $scope.showRegisterForm = function($event) {
        $event.preventDefault();
        $state.go('register', { share_link: $stateParams.share_link, family_invite_link: $stateParams.family_invite_link, room_link: $stateParams.room_link });
    };

    $scope.submit = function() {
        $scope.loading = true;

        if ($stateParams.remote_auth_key) {
            $scope.credentials['remote_auth_key'] = $stateParams.remote_auth_key;
        }

        if ($stateParams.client) {
            $scope.credentials['client'] = $stateParams.client;
        }

        if ($stateParams.remote_user) {
            $scope.credentials['remote_user'] = $stateParams.remote_user;
        }

        return users.login($scope.credentials).then(function() {
            $scope.credentials = {};
            goToDashboard();
        }, function(loginResponse) {
            if ('user' in loginResponse.data) {
                var deleted_at = moment(loginResponse.data.user.deleted_at).format('MM-DD-YYYY');
                var expired_at = moment(loginResponse.data.user.deleted_at).add(30, 'day').format('MM-DD-YYYY');
                if (loginResponse.data.user.deleted_by_id == loginResponse.data.user.id) {
                    utils.confirm({
                        title: 'welcomeBack',
                        content: utils.trans('accountActivationText', {deleted_at: deleted_at, expired_at: expired_at}),
                        subcontentHTML: 'Contact <a href="mailto:support@storyhives.com">StoryHive</a> if you have any questions.',
                        ok: 'loginTitle',
                        cancel: 'cancel',
                        onConfirm: function() {
                            $scope.credentials.activate = 1;
                            $scope.submit();
                        }
                    });
                } else {
                    utils.confirm({
                        title: 'recoverYourAccount',
                        content: utils.trans('accountDeactivationText', {deleted_at: deleted_at, expired_at: expired_at}),
                        subcontentHTML: 'Contact <a href="mailto:support@storyhives.com">StoryHive</a> if you wish to reactivate your account or have any questions.',
                        ok: 'okay',
                        type: 'alert',
                    });
                }
            } else {
                $scope.errors = loginResponse.data;
            }
        }).finally(function() {
            $scope.loading = false;
        });
    };

    // below is related to reset password and should be elsewhere.
    $scope.passResetCredentials = {
        email: ''
    };

    $scope.resetPasswordError = '';

    $scope.resetPassword = function() {
        $http.post($rootScope.baseUrl + 'password/email', $scope.passResetCredentials).then(function(response) {
            utils.showToast(response.data);
            $scope.resetPasswordError = '';
            $scope.closePasswordResetModal();
        }, function(response) {
            $scope.resetPasswordError = response.data.email || response.data;
        });
    };


    $scope.showPasswordResetModal = function($event) {
        dialogManager.showPasswordResetDialog($event);
    };

    $scope.closePasswordResetModal = function() {
        $scope.passResetCredentials = {};
        $mdDialog.hide();
    };
}]);

}());
