(function () {
    'use strict';

    angular.module('app')
    .directive('timelinePost', ['api', '$rootScope', 'users', '$http', 'fileTypes', 'utils', '$filter', '$q', 'dialogManager', function(api, $rootScope, users, $http, fileTypes, utils, $filter, $q, dialogManager) {
        return {
            restrict: 'E',
            scope: {
                post: '=post',
                options: '=',
                onDelete: "&",
                onPrivacy: "&"
            },
            templateUrl: 'assets/views/pages/timeline-post.html',
            link: function($scope, el, attrs) {
                var type = utils.stateIs('dashboard.timeline') || utils.stateIs('dashboard.buzz.timeline') || utils.stateIs('dashboard.hives.details.member') || utils.stateIs('dashboard.hives.details.member.post') ? 'post' : 'file';

                $scope.data = {
                    contents: 'Loading...',
                    loading: false,
                    loaded: false,
                    users: users,
                    commentsTotal: 0,
                    likesTotal: 0,
                    likedByUser: false,
                    fileOwner: '',
                    is_owner: users.current.id == $scope.post.user_id,
                    isImage: fileTypes.isImage($scope.post.file.mime),
                    selectedPrivacy: [],
                    selectedUser: [],
                    tags: [],
                    changedPrivacy: false,
                    options: [],
                    privacyList: [],
                    userList: [],
                };

                $scope.data.options = angular.copy($scope.options);

                $scope.closePrivacySelection = function(event) {
                    if (!$scope.data.selectedPrivacy.length && !$scope.data.selectedUser.length) {
                        return;
                    }

                    api.post.updatePrivacy($scope.post.id, $scope.data.selectedPrivacy, $scope.data.selectedUser).then(function(post) {
                        $scope.post.privacy = post.privacy;
                        $scope.post.included_users = post.included_users;
                        $scope.data.userList.splice.apply($scope.data.userList, [0, $scope.data.userList.length].concat(post.included_users));
                        $scope.data.privacyList.splice.apply($scope.data.privacyList, [0, $scope.data.privacyList.length].concat(post.privacy));
                        utils.showToast(utils.trans('post.postPrivacyChanged', true));
                        $scope.onPrivacy();
                    });
                };

                $scope.comments = [];
                $scope.newComment = "";
                $scope.newTags = [];
                $scope.newDescription = "";

                $scope.editingDescription = false;
                $scope.tagging = false;

                $scope.progress = {
                    description : false,
                    comments    : false,
                    postComment : false,
                    like        : false,
                    tags        : false
                };

                $scope.$watch('post', function() {
                    //loading content
                    $scope.data.contents = "Loading...";
                    $scope.data.userList.splice.apply($scope.data.userList, [0, $scope.data.userList.length].concat($scope.post.included_users));
                    $scope.data.privacyList.splice.apply($scope.data.privacyList, [0, $scope.data.privacyList.length].concat($scope.post.privacy));
                    api.file.htmlContent($scope.post.file.share_id).then(function success(response) {
                        if (response) {
                            response = response.replace(/<img src="([-:.?=\/a-zA-Z0-9]*)" alt="VideoId:([a-zA-Z0-9]*)"([a-z0-9 =":;%])*(\/)*>/g, '<video-preview data-src="$1" data-alt="$2" video="$2"></video-preview>');
                            
                            // If the user who's viewing the image is not the owner, prevent downloading
                            if(!$scope.data.is_owner) {
                                response = response.replace("<img ", '<img draggable="false" onContextMenu="return false;" ');
                            }

                            $scope.data.contents = response;
                        } else {
                            $scope.data.contents = "Unable to load post.";
                        }
                    }, function error() {
                        $scope.data.contents = "Unable to load preview.";
                    });

                    api.item.likes($scope.post.id, type).then(function(data) {
                        $scope.data.likesTotal = data.total;
                        $scope.data.likedByUser = data.user_like ? true : false;
                        $scope.data.likeUsernames = data.usernames_like;
                    });

                    $http.get( $rootScope.baseUrl + 'comments?parent=0&item_id='+$scope.post.id+'&type='+type).then( function (response) {
                        $scope.comments = response.data;
                        $scope.data.commentsTotal += $scope.comments.length;
                    });

                    api.file.getTags($scope.post.file.id).then(function(response) {
                        $scope.data.tags = tagsToAutoComplete(response);
                    });

                    if ( $scope.post.file.user_id != users.current.id ){
                        $http.get($rootScope.baseUrl + 'users/'+$scope.post.file.user_id).then( function (response) {
                            $scope.data.fileOwner = response.data;
                        });
                    } else {
                        $scope.data.fileOwner = users.current;
                    }
                });

                $scope.closeSelectBox = function () {
                    $("md-backdrop").trigger ("click");
                };

                $scope.tagPeople = function() {
                    $scope.newTags = $scope.data.tags;
                    $scope.tagging = true;
                };

                $scope.updateTags = function() {
                    $scope.progress.tags = true;

                    var tagIds = [];
                    var tagEmails = [];

                    angular.forEach($scope.newTags, function (tag, key) {
                        if ('id' in tag && tag.id) {
                            tagIds.push(tag.id);
                        } else {
                            tagEmails.push(tag.text);
                        }
                    });

                    api.file.updateTags($scope.post.file.id, tagIds, tagEmails).then(function(response) {
                        utils.showToast(response);
                        $scope.data.tags = $scope.newTags;
                        $scope.tagging = false;
                        $scope.progress.tags = false;
                        $scope.newTags = [];
                    }, function (error) {
                        utils.showToast(error.data);
                    });
                };

                $scope.updateDescription = function() {
                    if ($scope.progress.description) { return; }

                    $scope.progress.description = true;
                    var newDescription = $scope.newDescription.replace(/(\s|\t|(<br *\/?>))+$/, "");

                    api.file.updateDescription($scope.post.file.id, newDescription).then(function(data) {
                        $rootScope.$broadcast('activity.happened', 'edited', 'file', data);
                        utils.showToast('descriptionEdited', true);
                        $scope.post.file.description = $scope.newDescription;
                        $scope.editingDescription = false;
                    }, function(response) {
                        utils.showToast(response);

                    }).finally(function() {
                        $scope.progress.description = false;
                    });
                };

                $scope.loadTagList = function(query) {
                    var deferred = $q.defer();

                    api.file.getTagSuggestions($scope.post.file.id, query).then(function(tags) {
                        deferred.resolve(tagsToAutoComplete(tags));
                    }, function() {
                        deferred.resolve(false);
                    });
                    return deferred.promise;
                };

                $scope.cancelTagging = function () {
                    $scope.newTags = [];
                    $scope.tagging = false;
                    $scope.progress.tags = false;
                };

                $scope.cancelEdit = function() {
                    $scope.newDescription = "";
                    $scope.editingDescription = false;
                };

                $scope.editDescription = function() {
                    $scope.newDescription = $scope.post.file.description;
                    $scope.editingDescription = true;
                };

                $scope.deletePost = function() {
                      utils.confirm({
                          title: 'post.removePostFromTimeline',
                          content: 'post.removePostConfirm',
                          ok: 'post.removePost',
                          onConfirm: function() {
                            $scope.onDelete();
                            utils.showToast(utils.trans('post.removedPostSuccessful', true));
                          }
                      });
                };

                $scope.deleteComment = function(comment) {
                    $scope.comments.splice($scope.comments.indexOf(comment), 1);
                    $scope.data.commentsTotal -= 1;
                };

                $scope.addComment = function(comment) {
                    $scope.comments.push(comment);
                    $scope.data.commentsTotal += 1;
                };

                $scope.updateTotalWithReply = function(reply_count) {
                    $scope.data.commentsTotal += reply_count;
                };

                $scope.getAgoTime = function() {
                    return $filter('agoTime')($scope.getCreatedDateUTC());
                };

                $scope.toggleLike = function() {
                    api.item.toggleLike($scope.post.id, type).then(function(data) {
                        if (!data.likedByUser) {
                            $rootScope.$broadcast('activity.happened', 'unliked', 'file description', $scope.post.file);
                        } else {
                            $rootScope.$broadcast('activity.happened', 'liked', 'file description', $scope.post.file);
                        }
                        $scope.data.likedByUser = data.likedByUser;
                        $scope.data.likesTotal = data.likesTotal;
                    }, function failure(data) {
                        utils.showToast(data);
                    });
                };

                function tagsToAutoComplete(tags) {
                    var members = [];

                    angular.forEach(tags, function(value, key){
                        var member = {email: value.email, avatar_url: value.avatar_url};

                        if ('id' in value) {
                            member['id'] = value.id;
                            member['text'] = users.getName(value);
                        } else {
                            member['text'] = value.email;
                        }
                        members.push(member);
                    });
                    return members;
                }

                $scope.isHiveMember = function(email) {
                    if (angular.isObject(email)) {
                        if ( !email.email ) {
                            dialogManager.inviteAsMember(email.text).then(function(res) {
                                if (res == false) {
                                    removeTagPersonFromList(email.text);
                                }
                            }, function(){
                            });
                        }
                    }
                    return utils.validateEmail(email);
                };

                function removeTagPersonFromList(email) {
                    $scope.newTags = $scope.newTags.filter(function(tag) {
                        return (tag.text != email);
                    });
                }

                $rootScope.$on('invite.tag.cancelled', function(e, email) {
                    if ($scope.newTags.length) {
                        removeTagPersonFromList(email);
                    }
                });

                $scope.getCreatedDateUTC = function() {
                    return new Date($scope.post.created_at);
                };

                $scope.getCreatedDate = function() {
                    return $scope.post.created_at.split('T')[0];
                };
            }
        };
    }]);
}());
